<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="saveButtonTooltip">
          <PrimeButton
            class="c-circular-button mr-4"
            @click="emit('onCommit')"
            data-testid="btn-save"
            :disabled="!hasUpdates"
          >
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>
        <PrimeButton class="c-circular-button mr-4" @click="emit('onCancel')" data-testid="btn-cancel">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton v-if="editMode" class="c-circular-button mr-4" @click="onConfirmDelete" data-testid="btn-delete">
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>
        <PrimeButton class="c-circular-button" @click="onToggleAllPanels">
          <i class="pi pi-clone c-default-button c-circular-icon"></i>
          <span class="px-3">{{
            collapsedPanels ? t("product.panel.open-panels") : t("product.panel.close-panels")
          }}</span>
        </PrimeButton>

        <UnsavedChangesDialog
          position="top"
          :visible="unsavedChangesDialogVisible"
          :dialogHeader="t('common.unsaved-changes-header')"
          @cancelClicked="emit('stayOnPage')"
          @discardClicked="emit('routeToProductList')"
          @saveClicked="emit('onCommit')"
          ><template #content>{{ t("common.unsaved-changes-text") }}</template>
          <template #cancelBtn>{{ t("common.cancel") }}</template>
          <template #discardBtn>{{ t("common.discard") }}</template>
          <template #saveBtn>{{ t("common.save") }}</template>
        </UnsavedChangesDialog>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { UnsavedChangesDialog } from "@cumulus/components";
import { computed } from "vue";

const { t } = useI18n();
const confirm = useConfirm();

const props = defineProps<{
  hasUpdates: boolean;
  unsavedChangesDialogVisible: boolean;
  editMode: boolean;
  collapsedPanels: boolean;
}>();

const emit = defineEmits<{
  (e: "onCommit"): void;
  (e: "onDelete"): void;
  (e: "onCancel"): void;
  (e: "onToggleAllPanels", currentValue: boolean): void;
  (e: "routeToProductList"): void;
  (e: "stayOnPage"): void;
}>();

const onToggleAllPanels = () => {
  emit("onToggleAllPanels", props.collapsedPanels);
};

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("onDelete");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>
<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
