<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t(`product.warehouse.dialog-header`)"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @keydown.esc.stop="escapeDialog"
    :style="{ width: '40rem' }"
    :dismissableMask="true"
    :closable="false"
  >
    <Card class="mb-4">
      <template #content>
        <DataTable
          ref="warehouseAddTable"
          dataKey="id"
          id="product-warehouse-add"
          class="c-compact-datatable2"
          :value="filteredWarehouses"
          v-model:selection="selectedWarehouse"
          data-testid="product-warehouse-add"
          filterDisplay="menu"
          :autoLayout="true"
          responsiveLayout="scroll"
          :globalFilterFields="['name']"
          stripedRows
          :scrollable="true"
          :highlightOnSelect="true"
          selectionMode="multiple"
        >
          <template #empty>{{ t(`product.warehouse.no-warehouse-found`) }}</template>

          <Column selectionMode="multiple" headerStyle="width: 3rem" tabindex="-1"></Column>
          <Column field="name" :header="t(`product.warehouse.name`)" :sortable="true">
            <template #body="{ data }">
              <div>{{ data.name }}</div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>

    <template #footer>
      <PrimeButton
        ref="cancelBtn"
        :label="t(`common.cancel`)"
        data-testid="cancel-btn"
        @click="escapeDialog"
        class="c-dialog-default-button"
        severity="cancel"
        text
        :pt="{
          label: 'font-bold -mx-6',
        }"
      />
      <PrimeButton
        :label="t(`common.add`)"
        data-testid="add-btn"
        @click="addWarehouse"
        class="c-dialog-default-button"
      />
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";

const { t } = useI18n();

const props = defineProps<{
  showDialogAdd: boolean;
  selectedWarehouseIds: string[];
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "addWarehouse", value: string[]): void;
  (e: "update:showDialogAdd", value: boolean): void;
}>();

const warehouseAddTable = ref();
const cancelBtn = ref();

const visible = computed({
  get: () => props.showDialogAdd,
  set: (value) => emit("update:showDialogAdd", value),
});

watch(
  () => props.showDialogAdd,
  (show) => {
    if (show) {
      nextTick(() => {
        if (filteredWarehouses.value.length > 0) {
          warehouseAddTable.value.$el.querySelector("tbody tr:first-of-type").focus();
        } else {
          cancelBtn.value.$el.focus();
        }
      });
    }
  },
);

const selectedWarehouse = ref<Warehouse[]>([]);

const filteredWarehouses = computed<Warehouse[]>(() => {
  const result = props.warehouses.filter((warehouse: Warehouse) => {
    const index = props.selectedWarehouseIds?.findIndex((c: string) => c === warehouse.id);
    return index === -1;
  });
  return result;
});

const escapeDialog = () => {
  visible.value = false;
  document.getElementById("add-warehouse-button")?.focus();
};

const addWarehouse = () => {
  emit(
    "addWarehouse",
    selectedWarehouse.value.map((w) => w.id),
  );
  escapeDialog();
};
</script>

<style lang="scss"></style>
