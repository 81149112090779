<template>
  <PrimeDialog
    :visible="visibleAttributeFormDialog"
    :header="isEditing ? t('common.save') : t('common.add')"
    :modal="true"
    class="c-attribute-dialog"
    data-testid="attribute-form-dialog"
    @update:visible="visibleAttributeFormDialog = false"
  >
    <div class="flex-wrap mx-4">
      <div class="col-span-12">
        <AttributeName v-model:attributeName="attribute.name" :attribute="attribute" :attributes="attributes" />
      </div>
      <div class="field col-span-12" v-if="isEditing">
        <AttributeState v-model:attributeState="attribute.attributeState" />
        <small class="p-error" data-testid="attribute-state-error" v-if="showIsActiveError">{{
          t("attribute.error.attribute-active")
        }}</small>
      </div>
      <div class="field col-span-12 mt-6">
        <AttributeValues v-model:attributeValues="attribute.values" />
      </div>
    </div>
    <template #footer>
      <div class="flex flex-row-reverse justify-between">
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="isEditing ? t('common.save') : t('common.add')"
            data-testid="btn-commit"
            @click="onCommit"
            icon="pi pi-check"
            :pt="{
              label: '-mx-4',
            }"
          />
        </div>
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="onCancel"
            class="mr-4"
            severity="cancel"
            text
            :pt="{
              label: 'font-bold -mx-6',
            }"
            icon="pi pi-times"
          />
          <PrimeDialog
            data-testid="unsaved-changes-dialog"
            v-model:visible="unsavedChangesDialogVisible"
            :header="t('common.unsaved-changes-header')"
            :style="{ width: '30vw' }"
            position="center"
            :modal="true"
          >
            <p class="p-6">
              {{ t("common.unsaved-changes-text") }}
            </p>
            <template #footer>
              <PrimeButton :label="t('common.cancel')" @click="unsavedChangesDialogVisible = false" text />
              <PrimeButton :label="t('common.ignore')" @click="visibleAttributeFormDialog = false" text />
              <PrimeButton
                :label="t('common.save')"
                icon="pi pi-check"
                @click="onCommit"
                :pt="{
                  label: '-mx-4',
                }"
              />
            </template>
          </PrimeDialog>
        </div>
        <div class="flex items-center justify-center" v-if="isEditing">
          <PrimeButton
            :label="t('common.delete')"
            data-testid="delete-btn"
            @click="confirmDelete"
            :disabled="isSaving"
            class="p-button-secondary"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { Attribute } from "@/repositories/attribute/model/Attribute";
import { AttributeState as State } from "@/repositories/attribute/model/AttributeState";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { computed, onMounted, ref, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

import AttributeName from "./AttributeName.vue";
import AttributeState from "./AttributeState.vue";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import { NewAttribute } from "@/repositories/attribute/model/NewAttribute";
import AttributeValues from "./AttributeValues.vue";

const props = defineProps<{
  showDialog: boolean;
  attribute: Attribute;
  attributes: Attribute[];
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "reloadAttributeList"): void;
  (e: "updateAttribute", value: Attribute): void;
  (e: "deleteAttribute", value: string): void;
}>();

const { t } = useI18n();
const confirm = useConfirm();
const val = useValidate();
const toast = useCumulusToast(useToast());
const isEditing = ref(false);
const isSaving = ref(false);
const showIsActiveError = ref(false);
const attribute = ref<Attribute>(props.attribute);
const initialAttribute = ref<Attribute>(cloneDeep(attribute.value));
const unsavedChangesDialogVisible = ref();

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const { createAttribute, updateAttribute, deleteAttribute } = useAttribute();

const visibleAttributeFormDialog = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const confirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      onDeleteAttribute();
    },
  });
};

const onDeleteAttribute = async () => {
  if (attribute.value.attributeState === State.Active) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    showIsActiveError.value = true;
    return;
  }
  try {
    isSaving.value = true;

    await deleteAttribute(attribute.value.id);

    toast.add({
      severity: "success",
      summary: t("attribute.toast.delete-success.summary"),
      detail: t("attribute.toast.delete-success.detail", { name: attribute.value.name }),
      closable: true,
    });
    emit("deleteAttribute", attribute.value.id);
    visibleAttributeFormDialog.value = false;
  } finally {
    isSaving.value = false;
  }
};

const onCommit = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    if (isEditing.value) {
      await updateAttribute(attribute.value);

      toast.add({
        severity: "success",
        summary: t("attribute.toast.update-success.summary"),
        detail: t("attribute.toast.update-success.detail", { name: attribute.value.name }),
        closable: true,
      });
      emit("updateAttribute", attribute.value);
    } else {
      const newAttribute: NewAttribute = {
        name: attribute.value.name,
        values: attribute.value.values,
      };

      await createAttribute(newAttribute);

      toast.add({
        severity: "success",
        summary: t("attribute.toast.add-success.summary"),
        detail: t("attribute.toast.add-success.detail", { name: attribute.value.name }),
        closable: true,
      });
      emit("reloadAttributeList");
    }
    visibleAttributeFormDialog.value = false;
  } finally {
    isSaving.value = false;
  }
};

const onInit = () => {
  if (props.attribute.id) {
    isEditing.value = true;
  }
};

onMounted(onInit);

const hasUpdates = computed(() => {
  return !isEqual(attribute.value, initialAttribute.value);
});
const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    visibleAttributeFormDialog.value = false;
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    if (unsavedChangesDialogVisible.value) {
      unsavedChangesDialogVisible.value = false;
      previouslyFocusedInput.value?.focus();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    visibleAttributeFormDialog.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown, true);
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown, true);
});
</script>

<style lang="scss">
.c-attribute-dialog {
  width: 90vw;

  @media screen and (min-width: 992px) {
    width: 60vw;
    max-width: 45rem;
  }
}
</style>
