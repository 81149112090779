<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <ProductHeader
    :editMode="false"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @onCancel="onCancel"
    @stayOnPage="stayOnPage"
    @routeToProductList="routeToProductList"
    @onCommit="saveProduct"
    :collapsedPanels="panelCollapsed"
    @onToggleAllPanels="onToggleAllPanels()"
  />
  <div class="c-add-product">
    <div class="grid grid-cols-1 gap-4 mb-4">
      <Card>
        <template #content>
          <div class="grid grid-cols-12 gap-4" id="general-info">
            <div class="col-span-12 lg:col-span-8 lg:border-r pr-8">
              <div class="grid grid-cols-12">
                <div class="col-span-12 lg:col-span-4">
                  <ProductNumber v-model:productNumber="product.productNumber" :focusInput="true" :is-editing="false" />
                </div>
                <div class="col-span-12 lg:col-span-8">
                  <Name v-model:name="product.name" :focusInput="setFocusProductName" />
                </div>
                <div class="col-span-12 lg:col-span-4">
                  <Gtin v-model:gtin="product.gtin" />
                </div>
                <div class="col-span-12 lg:col-span-8">
                  <Description v-model:description="product.description" />
                </div>
                <div class="col-span-12 lg:col-span-6">
                  <ProductHierarchySearchInput v-model:productHierarchyId="product.productHierarchyId" />
                </div>
                <div class="col-span-12 lg:col-span-3">
                  <SelectAccountGroup v-model:accountGroupId="product.accountGroupId" />
                </div>
                <div class="col-span-12 lg:col-span-3">
                  <Manufacturer v-model:manufacturerId="product.manufacturerId" />
                </div>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-2 lg:border-r lg:pt-6">
              <div class="grid grid-cols-12">
                <div class="col-span-12">
                  <ProductType v-model:productType="product.productType" />
                </div>
                <div class="col-span-12">
                  <ProductStatus v-model:productStatus="product.productStatus" />
                </div>
                <div class="col-span-12">
                  <SalesUnit v-model:salesUnit="product.salesUnitId" />
                </div>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-2 lg:pt-4">
              <DimensionAndWeight v-model:dimension="product.dimension" v-model:weight="product.weight" />
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      :header="t('common.product-info')"
      toggleable
      :collapsed="panelCollapsedInfo"
      class="mb-4"
      id="productInfoCollapsed"
      :pt:header:onClick="() => (panelCollapsedInfo = !panelCollapsedInfo)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8">
          <ProductInfo v-model:productInfo="product.productInfo" />
        </div>
        <div class="col-span-12 lg:col-span-4">
          <MediaTabs
            v-model:productImages="product.productImages"
            v-model:productVideos="product.media"
            v-model:productDocuments="product.documents"
          />
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('common.warehouse-and-client')"
      toggleable
      :collapsed="panelCollapsedWarehouseClient"
      class="mb-4"
      id="warehouseClientCollapsed"
      :pt:header:onClick="() => (panelCollapsedWarehouseClient = !panelCollapsedWarehouseClient)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8 lg:border-r">
          <Warehouse
            :warehouseIds="product.warehouseIds"
            @addWarehouse="addWarehouse"
            @deleteWarehouse="deleteWarehouse"
          />
        </div>

        <div class="col-span-12 lg:col-span-4">
          <Client :clients="product.clientIds" @addClient="addClient" @deleteClient="deleteClient" />

          <WebshopActive v-model:activeOnWebshop="product.activeOnWebshop" />
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('product.supplier.label')"
      toggleable
      :collapsed="panelCollapsedSupplier"
      class="mb-4"
      id="suppliersCollapsed"
      :pt:header:onClick="() => (panelCollapsedSupplier = !panelCollapsedSupplier)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <SupplierPrices
            :supplierPrices="product.supplierPrices"
            :suppliers="suppliers"
            :clientCurrencyIso="userClient.currencyIso"
            :currencies="currencies"
            @mainSupplierCostPriceUpdated="($event) => (mainSupplierCostPrice = $event)"
            @update:supplierPrices="updateSupplierPrices"
            @addSupplierPrice="addSupplierPrice"
            @deleteSupplierPrice="deleteSupplierPrice"
          />
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('common.prices-and-fees')"
      toggleable
      :collapsed="panelCollapsedPricesAndFees"
      class="mb-4"
      id="pricesAndFeesCollapsed"
      :pt:header:onClick="() => (panelCollapsedPricesAndFees = !panelCollapsedPricesAndFees)"
    >
      <div class="grid grid-cols-1 gap-4">
        <div>
          <Price
            :mainSupplierCostPrice="mainSupplierCostPrice"
            :clientCurrencyIso="userClient.currencyIso"
            :prices="product.prices"
            :vatPercentage="defaultOutgoingVatPercentage(product)"
            @updatePrice="updatePrice($event.price)"
          />
        </div>

        <div>
          <Tax :productTaxes="product.taxes" @update:productTaxes="updateTaxes" />
        </div>
        <div>
          <ExciseDuties />
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('common.attributes-and-connections')"
      toggleable
      :collapsed="panelCollapsedAttribues"
      class="mb-4"
      id="attributesCollapsed"
      :pt:header:onClick="() => (panelCollapsedAttribues = !panelCollapsedAttribues)"
    >
      <div class="grid grid-cols-12 gap-4 mt-4">
        <div class="col-span-12 lg:col-span-4">
          <ProductAttributes v-model:productAttributes="product.attributes" />
        </div>
        <div class="col-span-12 lg:col-span-4 lg:border-r">
          <AlternativeProduct :alternativeProductIds="product.alternativeProductIds" />
        </div>
        <div class="col-span-12 lg:col-span-4">
          <Accessory :accessoryIds="product.accessoryProductIds" />
        </div>
      </div>
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, onBeforeUnmount, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { NewProduct } from "@/repositories/product/model/NewProduct";
import { SupplierPrice } from "@/repositories/product/model/SupplierPrice";
import { Price as PriceModel } from "@/repositories/product/model/Price";
import { usePriceFunctions } from "../PriceFunctions";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { Supplier } from "@/repositories/supplier/model/Supplier";
import { usePriceGroup } from "@/repositories/price-group/PriceGroupService";
import { useProductStore } from "@/repositories/product/ProductStore";
import { BeforeUnloadBrowserTab } from "@cumulus/components";

import ProductNumber from "../components/ProductNumber.vue";
import Name from "../components/Name.vue";
import Description from "../components/Description.vue";
import Gtin from "../components/Gtin.vue";
import SalesUnit from "../components/sales-unit/SalesUnit.vue";
import SupplierPrices from "../components/SupplierPrices.vue";
import Client from "../components/Client.vue";
import Manufacturer from "../components/manufacturer/Manufacturer.vue";
import ProductType from "../components/ProductType.vue";
import ProductInfo from "../components/ProductInfo.vue";
import AlternativeProduct from "../components/AlternativeProduct.vue";
import Accessory from "../components/Accessory.vue";
import Tax from "../components/Tax.vue";
import ProductStatus from "../components/ProductStatus.vue";
import Price from "../components/Price.vue";
import MediaTabs from "../components/MediaTabs.vue";
import WebshopActive from "../components/WebshopActive.vue";
import DimensionAndWeight from "../components/DimensionAndWeight.vue";
import ExciseDuties from "../components/ExciseDuties.vue";
import Warehouse from "../components/Warehouse.vue";
import ProductAttributes from "../components/attribute/ProductAttributes.vue";
import ProductHeader from "../components/ProductHeader.vue";
import ProductHierarchySearchInput from "../components/ProductHierarchySearchInput.vue";
import { useAccessoriesStore } from "@/stores/ProductAccessoryStore";
import { useAlternativesStore } from "@/stores/ProductAlternativesStore";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { ProductTax } from "@/repositories/product/model/ProductTax";
import { Currency } from "@/repositories/currency/model/Currency";
import { useCurrencyService } from "@/repositories/currency/CurrencyService";
import { Client as ClientModel } from "@/repositories/client/model/Client";
import { useClient } from "@/repositories/client/ClientService";
import { useAuth } from "@cumulus/event-bus";
import { useAccountGroupStore } from "@/stores/AccountGroupStore";
import SelectAccountGroup from "../components/account-group/SelectAccountGroup.vue";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const router = useRouter();

const product = ref<NewProduct>(new NewProduct());
const suppliers = ref<Supplier[]>([]);
const currencies = ref<Currency[]>([]);
const userClient = ref<ClientModel>(new ClientModel());
const mainSupplierCostPrice = ref<number>(0);

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const initialProduct = ref<NewProduct | null>(null);
const { defaultOutgoingVatPercentage } = usePriceFunctions();
const { getAllSuppliers } = useSupplier();
const { getDefaultPriceGroupByUsersClient } = usePriceGroup();
const { createProduct } = useProductStore();
const accessoriesStore = useAccessoriesStore();
const alternativesStore = useAlternativesStore();
const { getAllCurrencies } = useCurrencyService();
const { getAllAccountGroups } = useAccountGroupStore();
const { getClient } = useClient();
const { getAuthHeaders } = useAuth();
const confirmedDiscard = ref(false);

const routeToProductList = async () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/product/search") === -1) {
    router.push({ name: "product-search", query: { search: "" } });
  } else {
    router.back();
  }
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Nested validation
const validate = useValidate();

const saveProduct = async () => {
  //Wait for onblur functions
  await timeout(300);

  validate.value.$touch();

  if (!(await validate.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await createProduct(product.value);

  toast.add({
    severity: "success",
    summary: t("product.toast.success-add.summary"),
    detail: t("product.toast.success-add.detail", { name: product.value.name }),
    closable: true,
  });

  routeToProductList();
};

const addSupplierPrice = async (supplierPrice: SupplierPrice) => {
  if (product.value.supplierPrices.length === 0) {
    supplierPrice.isDefaultSupplier = true;
  }
  product.value.supplierPrices.push(supplierPrice);
  const index = product.value.supplierPrices.length - 1;
  await nextTick();
  const productNumberInput = document.getElementById(`supplier-product-number-${index}`) as HTMLInputElement;
  if (productNumberInput) {
    productNumberInput.focus();
  }
};

const deleteSupplierPrice = (supplierPrice: SupplierPrice) => {
  const index = product.value.supplierPrices.findIndex((s) => s.id === supplierPrice.id);
  if (index !== -1) product.value.supplierPrices.splice(index, 1);
  if (supplierPrice.isDefaultSupplier === true && product.value.supplierPrices.length > 0)
    product.value.supplierPrices[0].isDefaultSupplier = true;
};

const addClient = (clientId: string) => {
  product.value.clientIds.push(clientId);
};

const deleteClient = (clientId: string) => {
  const index = product.value.clientIds.findIndex((c) => c === clientId);
  if (index !== -1) product.value.clientIds.splice(index, 1);
};

const addWarehouse = (warehouseId: string[]) => {
  product.value.warehouseIds = [...product.value.warehouseIds, ...warehouseId];
};

const deleteWarehouse = (warehouseId: string) => {
  const index = product.value.warehouseIds.findIndex((c) => c === warehouseId);
  if (index !== -1) product.value.warehouseIds.splice(index, 1);
};

const updatePrice = (price: PriceModel) => {
  const priceToUpdate = product.value.prices.find((s) => s.priceGroupId === price.priceGroupId);
  if (priceToUpdate === undefined) return;
  priceToUpdate.listPrice = price.listPrice;
};

const updateTaxes = (taxes: ProductTax[]) => {
  product.value.taxes = taxes;
};

const updateSupplierPrices = (supplierPrices: SupplierPrice[]) => {
  product.value.supplierPrices = supplierPrices;
};

const getCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};

const getAccountGroups = async () => {
  await getAllAccountGroups();
};

const getUserClient = async () => {
  const authHeaders = await getAuthHeaders();
  const clientId = authHeaders.clientId;
  userClient.value = await getClient(clientId);
};

const getPrice = async () => {
  const defaultPrice = await getDefaultPriceGroupByUsersClient();

  const price = new PriceModel();
  price.priceGroupId = defaultPrice.id;
  price.priceGroupName = defaultPrice.name;
  price.currencyIso = defaultPrice.currencyIso;

  product.value.prices.push(price);
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);

  await Promise.all([getSuppliers(), getCurrencies(), getPrice(), getUserClient(), getAccountGroups()]);

  accessoriesStore.useProduct(product.value);
  alternativesStore.useProduct(product.value);
  initialProduct.value = cloneDeep(product.value);
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToProductList();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const getSuppliers = async () => {
  suppliers.value = await getAllSuppliers();
};

const setFocusProductName = ref(false);

const hasUpdates = computed(() => {
  if (initialProduct.value === null) return false;
  return !isEqual(product.value, initialProduct.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToProductList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

useShortcut(ShortcutAction.save, saveProduct);

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const panelCollapsedInfo = ref(false);
const panelCollapsedWarehouseClient = ref(false);
const panelCollapsedSupplier = ref(false);
const panelCollapsedPricesAndFees = ref(false);
const panelCollapsedAttribues = ref(false);

const panelCollapsed = computed(() => {
  return (
    panelCollapsedInfo.value &&
    panelCollapsedWarehouseClient.value &&
    panelCollapsedSupplier.value &&
    panelCollapsedPricesAndFees.value &&
    panelCollapsedAttribues.value
  );
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedInfo.value = newState;
  panelCollapsedWarehouseClient.value = newState;
  panelCollapsedSupplier.value = newState;
  panelCollapsedPricesAndFees.value = newState;
  panelCollapsedAttribues.value = newState;
};
</script>

<style scoped lang="scss">
.c-add-product {
  margin: var(--default-content-margin);
  margin-bottom: 7rem;
}
.c-fields-container {
  border: var(--field-group-border);
  border-radius: var(--field-group-border-radius);
}

:deep(.p-galleria .p-galleria-thumbnail-container) {
  background: var(--maskbg);
  padding: 0.25rem;
  border-bottom-left-radius: var(--card-border-radius);

  @media (max-width: 767px) {
    border-radius: 0;
  }
}

:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev),
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next) {
  color: #fff;
  margin: 0.1rem;
}
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus) {
  box-shadow: none;
}
</style>
