<template>
  <div v-if="productVideos.length > 0">
    <Carousel :value="productVideos" :numVisible="2" :numScroll="1" :responsiveOptions="responsiveOptions">
      <template #item="{ data }">
        <div class="flex-1 m-2 p-4">
          <VideoCard
            :video="data"
            @openPlayDialog="openPlayVideoModal(data)"
            @deleteVideo="removeVideo(productVideos.indexOf(data))"
            @videoEdited="updateVideo($event, productVideos.indexOf(data))"
          />
        </div>
      </template>
    </Carousel>
  </div>
  <span v-else class="text-gray-500 text-center block mt-4">
    {{ t("product.video.no-videos-found") }}
  </span>

  <PrimeButton
    icon="pi pi-plus"
    data-testid="open-video-dialog-button"
    class="mt-4"
    text
    type="button"
    :label="t('product.video.add')"
    @click="openVideoDialog(new Media(), undefined)"
  />

  <PrimeDialog v-model:visible="showPlayVideoDialog" :header="selectedVideo?.name" :modal="true">
    <iframe
      v-if="selectedVideo"
      :src="`https://www.youtube.com/embed/${extractYouTubeId(selectedVideo.uri)}`"
      width="100%"
      height="315"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
      class="w-full aspect-video"
    ></iframe>
  </PrimeDialog>

  <AddVideoDialog
    v-if="showDialog"
    v-model:showDialog="showDialog"
    v-model:productVideo="selectedVideo"
    :index="activeIndex"
    @videoAdded="addVideo"
    @videoUpdated="updateVideo"
    @videoRemoved="removeVideo"
    data-testid="video-dialog"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Media } from "@/repositories/product/model/Media";
import { useI18n } from "vue-i18n";
import VideoCard from "./VideoCard.vue";
import AddVideoDialog from "./AddVideoDialog.vue";
import Carousel from "primevue/carousel";

const { t } = useI18n();

const productVideos = defineModel<Media[]>("productVideos", {
  required: true,
});

const showPlayVideoDialog = ref<boolean>(false);
const selectedVideo = ref<Media>(new Media());
const showDialog = ref<boolean>(false);
const activeIndex = ref<number>(0);

const responsiveOptions = ref([
  {
    breakpoint: "2100px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "1023px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "750",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);

const openPlayVideoModal = (video: Media) => {
  selectedVideo.value = video;
  showPlayVideoDialog.value = true;
};

const extractYouTubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

const openVideoDialog = (productVideo: Media, index?: number) => {
  if (index !== undefined) {
    activeIndex.value = index;
    selectedVideo.value = productVideo;
  } else {
    selectedVideo.value = new Media();
  }

  showDialog.value = true;
};

const addVideo = (video: Media) => {
  productVideos.value.push(video);
  showDialog.value = false;
};

const removeVideo = (index: number) => {
  productVideos.value.splice(index, 1);
  showDialog.value = false;
};

const updateVideo = (video: Media, index: number) => {
  productVideos.value[index] = video;
  showDialog.value = false;
};
</script>
