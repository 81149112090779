<template>
  <div class="space-y-4">
    <DataTable :value="attributeValues" dataKey="id" class="p-datatable-sm">
      <Column field="value" :header="t('attribute.value')">
        <template #body="{ data, index }">
          <AttributeValue
            v-model:value="data.value"
            :languageIso="languageIso"
            :index="index"
            @updateValue="updateValue(data, 'value', $event as string)"
          />
        </template>
      </Column>
      <Column field="description" :header="t('attribute.description')">
        <template #body="{ data, index }">
          <AttributeDescription
            v-model:description="data.description"
            :languageIso="languageIso"
            :index="index"
            @updateValue="updateValue(data, 'description', $event as string)"
          />
        </template>
      </Column>

      <Column
        v-if="attributeType == AttributeType.Color"
        field="additionalProperties.hexCode"
        :header="t('attribute.color-code')"
      >
        <template #body="{ data, index }">
          <div class="flex justify-between">
            <InputText
              :modelValue="data.additionalProperties.hexCode"
              @update:modelValue="updateAdditionalProperty(data, 'hexCode', $event as string)"
              class="w-24 p-inputtext-sm"
              :data-testid="`attribute-color-code-${languageIso}-${index}`"
            />
            <ColorPicker
              v-model="data.additionalProperties.hexCode"
              format="hex"
              defaultColor="#4D7694"
              @update:modelValue="updateAdditionalProperty(data, 'hexCode', $event)"
              pt:preview:class="h-8 w-8 rounded-full"
              :pt:preview:data-testid="`attribute-color-code-preview-${languageIso}-${index}`"
            />
          </div>
        </template>
      </Column>

      <Column class="w-10">
        <template #body="{ data, index }">
          <PrimeButton
            icon="pi pi-trash"
            :data-testid="`btn-delete-attribute-value-${languageIso}-${index}`"
            class="p-button-text p-button-rounded p-button-danger p-button-sm"
            @click.stop="onConfirmDelete($event, data)"
          />
        </template>
      </Column>
    </DataTable>

    <small class="p-error" v-if="val.attributeValues.$error" data-testid="attribute-values-error">
      {{ val.attributeValues.$errors[0].$message }}
    </small>

    <div class="flex justify-end">
      <PrimeButton
        :label="t('attribute.add-value')"
        icon="pi pi-plus"
        @click="emit('addValue')"
        class="p-button-sm"
        text
        :data-testid="`btn-add-attribute-value-${languageIso}`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AttributeType } from "@/models/attribute/AttributeType";
import { AttributeValueByLanguage } from "@/models/attribute/AttributeValueByLanguage";
import { LanguageIsoType } from "@/models/language/LanguageIsoType";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import AttributeValue from "./AttributeValue.vue";
import AttributeDescription from "./AttributeDescription.vue";

const props = defineProps<{
  attributeValues: AttributeValueByLanguage[];
  attributeType: AttributeType;
  languageIso: LanguageIsoType;
}>();

const emit = defineEmits<{
  (e: "addValue"): void;
  (e: "updateValue", value: AttributeValueByLanguage): void;
  (e: "deleteValue", value: string): void;
}>();

const updateValue = (data: AttributeValueByLanguage, field: "value" | "description", value: string) => {
  emit("updateValue", { ...data, [field]: value });
};

const confirm = useConfirm();
const { t } = useI18n();

const onConfirmDelete = (event: Event, value: AttributeValueByLanguage) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      emit("deleteValue", value.id);
    },
  });
};

const updateAdditionalProperty = (data: AttributeValueByLanguage, property: string, value: string) => {
  const updatedData = {
    ...data,
    additionalProperties: {
      ...data.additionalProperties,
      [property]: value,
    },
  };
  emit("updateValue", updatedData);
};

const rules = {
  attributeValues: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
