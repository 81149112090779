<template>
  <DataTable
    :value="clientsComputed"
    class="c-product-edit-client-table c-compact-datatable"
    dataKey="id"
    data-testid="product-clients"
    :autoLayout="true"
    responsiveLayout="stack"
    breakpoint="991px"
    :globalFilterFields="['name']"
    stripedRows
  >
    <template #empty>
      <div class="c-list-not-found" :class="{ 'p-invalid': val.clients.$error }">
        {{ t(`product.client.no-client-found`) }}
      </div></template
    >

    <div class="p-6 font-bold">
      <label>{{ t("product.client.label") }}</label>
    </div>

    <Column field="name" :header="t(`product.client.name`)" :sortable="true">
      <template #body="{ data }">
        {{ data.name }}
      </template>
    </Column>

    <Column class="c-product-edit-client-column-remove">
      <template #body="slotProps">
        <PrimeButton
          icon="pi pi-trash"
          data-testid="delete-client-btn"
          class="p-button-text p-button-rounded p-button-secondary"
          @click="onConfirmDelete($event, slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>

  <PrimeButton
    type="button"
    class="p-button-text p-button-sm p-6 c-under-construction"
    data-testid="add-client-btn"
    @click="showDialogAdd = true"
    id="add-client-button"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </PrimeButton>

  <small id="client-help" class="p-error" data-testid="client-error" v-if="val.clients.$error">
    {{ val.clients.$errors[0].$message }}
  </small>

  <ClientDialogAdd
    :selectedClients="clients"
    :allClients="allClients"
    v-model:showDialogAdd="showDialogAdd"
    @addClient="$emit('addClient', $event)"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import ClientDialogAdd from "./ClientDialogAdd.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { useClient } from "@/repositories/client/ClientService";
import { Client } from "@/repositories/client/model/Client";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const { getAllClients } = useClient();
const { t } = useI18n();

const props = defineProps<{
  clients: string[];
}>();

const emit = defineEmits<{
  (e: "addClient", value: string): void;
  (e: "deleteClient", value: string): void;
}>();

const showDialogAdd = ref(false);
const loading = ref(true);
const allClients = ref<Client[]>([]);

const clientsComputed = computed<Client[]>(() => {
  return allClients.value.filter((client) => props.clients.includes(client.id));
});

const loadClients = async () => {
  loading.value = true;
  try {
    allClients.value = await getAllClients();
  } finally {
    loading.value = false;
  }
};

onMounted(loadClients);

const deleteClient = async (client: Client) => {
  emit("deleteClient", client.id);
  await nextTick();
  document.getElementById("add-client-button")?.focus();
};

const rules = {
  clients: {
    required: required,
  },
};
const val = useVuelidate(rules, props);

const onConfirmDelete = (event: Event, client: Client) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      deleteClient(client);
    },
  });
};
</script>

<style lang="scss">
.c-product-edit-client-table {
  .p-datatable-thead > tr > th {
    padding: 0;
  }

  .p-datatable-tbody td.c-product-edit-client-column-remove {
    text-align: right;
  }

  .p-datatable-tbody > tr > td {
    padding: 0.15rem 0;
  }

  .p-inputnumber-input {
    max-width: 8rem;
  }

  .p-datatable-footer {
    padding: 1rem 0;
    .p-button.p-button-sm {
      padding: 0.35rem 0;
    }
  }
}

.c-product-edit-client-column-remove {
  min-width: 3rem;
  padding: 0;

  .p-column-header-content {
    float: right;
  }
}
</style>
