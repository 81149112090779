<template>
  <DataTable
    ref="accountGroupTableRef"
    :value="accountGroups"
    class="c-compact-datatable c-account-group-table"
    dataKey="id"
    :autoLayout="true"
    responsiveLayout="scroll"
    selectionMode="single"
    :loading="loading"
    stripedRows
    sortField="name"
    :sortOrder="1"
    :rows="pageSize"
    :rowsPerPageOptions="[20, 50, 100]"
    data-testid="account-group-list"
    scrollable
    scrollHeight="75vh"
    v-model:filters="filters"
    @row-select="onRowSelect"
  >
    <template #header>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-search" />
        <InputText
          ref="searchInputRef"
          v-model="filters['global'].value"
          :placeholder="t('common.search')"
          @focus="selectAllOnFocus"
          @keyup.enter="onSearchInputEnterKey"
          @keypress.tab="focusAccountGroupTable"
          @keydown.down="focusAccountGroupTable"
          class="w-full"
          autocomplete="off"
          data-testid="account-group-search"
        />
      </span>
    </template>

    <Column field="name"></Column>
    <Column class="text-center">
      <template #body="{ data, index }">
        <i
          class="pi pi-pencil c-disabled"
          v-tooltip.bottom="{ value: t('product.account-group.standard-cant-be-altered') }"
          v-if="data.name === 'Standard'"
        ></i>

        <PrimeButton
          v-else
          :data-testid="`edit-account-group-${index}`"
          type="button"
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          @click.stop="emit('editAccountGroupClicked', data)"
        ></PrimeButton>
      </template>
    </Column>

    <template #empty>
      <div class="p-text-center p-text-secondary p-text-italic" ref="emptyFilterRef">
        <p>{{ t("product.account-group.no-account-groups-found") }}</p>
      </div>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { AccountGroup } from "@/product/models/AccountGroup";
import { FilterMatchMode, FilterOperator } from "@primevue/core/api";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import { nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";

defineProps<{
  accountGroups: AccountGroup[];
  loading: boolean;
  pageSize: number;
}>();

const { t } = useI18n();
const accountGroupTableRef = ref();
const emptyFilterRef = ref();

const emit = defineEmits<{
  (e: "editAccountGroupClicked", value: AccountGroup): void;
  (e: "accountGroupSelected", value: AccountGroup): void;
  (e: "createNewAccountGroup"): void;
}>();

const onRowSelect = (event: DataTableRowDoubleClickEvent) => {
  emit("accountGroupSelected", event.data);
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const filters = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  name: { operator: FilterOperator.AND, constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }] },
});

const focusAccountGroupTable = (event: Event) => {
  event.stopPropagation();
  accountGroupTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const onSearchInputEnterKey = (event: Event) => {
  event.stopPropagation();

  if (emptyFilterRef.value === undefined) {
    focusAccountGroupTable(event);
  } else {
    emit("createNewAccountGroup");
  }
};

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});
</script>

<style lang="scss">
.c-disabled {
  cursor: pointer;
  opacity: 0.6;
}

.c-account-group-table {
  .p-datatable-header {
    padding: 0;
    margin-bottom: 0.5rem;

    .p-inputtext {
      border: none;
      border-bottom: 2px solid var(--primary-color);
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-color: var(--search-dropdown-border-color);
      }
    }
    .p-input-icon-left > i:first-of-type {
      color: var(--search-dropdown-border-color);
      font-weight: bolder;
    }
  }
}
</style>
