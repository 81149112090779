<template>
  <ProgressSpinner v-if="saving" />
  <div v-if="!saving">
    <div class="field">
      <label for="sales-unit-name">
        {{ t("product.sales-unit.name") }}
      </label>
      <InputText
        ref="salesUnitNameRef"
        type="text"
        class="inputfield w-full"
        maxlength="500"
        v-model="salesUnitRef.name"
        :class="{ 'p-invalid': val.name.$error }"
        :placeholder="t('placeholder.type', { property: t('product.sales-unit.name').toLowerCase() })"
        data-testid="sales-unit-name"
        @focus="selectAllOnFocus"
        @keydown.esc.stop="$emit('onCancel')"
      />
      <small id="sales-unit-name-help" class="p-error" data-testid="sales-unit-name-error" v-if="val.name.$error">
        {{ val.name.$errors[0].$message }}
      </small>
    </div>

    <div class="field">
      <label for="sales-unit-description">
        {{ t("product.sales-unit.description") }}
      </label>
      <InputText
        type="text"
        class="inputfield w-full"
        maxlength="500"
        v-model="salesUnitRef.description"
        :placeholder="t('placeholder.type', { property: t('product.sales-unit.description').toLowerCase() })"
        data-testid="sales-unit-description"
        @focus="selectAllOnFocus"
        @keydown.esc.stop="$emit('onCancel')"
      />
    </div>
  </div>

  <div class="w-full">
    <div class="flex justify-between">
      <PrimeButton
        type="button"
        :label="t('common.cancel')"
        class="c-dialog-default-button"
        @click="$emit('onCancel')"
        tabindex="1"
      ></PrimeButton>

      <PrimeButton
        v-if="editMode"
        type="button"
        :label="t('common.delete')"
        class="c-delete-button"
        @click="confirmDeleteSalesUnit"
        data-testid="btn-cg-delete"
        :disabled="saving"
      ></PrimeButton>

      <PrimeButton
        type="button"
        :label="editMode ? t('common.save') : t('common.add')"
        class="c-success-button"
        @click="onCommit"
        data-testid="btn-cg-commit"
        :disabled="saving"
      ></PrimeButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useVuelidate } from "@vuelidate/core";
import { useConfirm } from "primevue/useconfirm";
import { required } from "@/locales/i18n-validators";
import { NIL as emptyUuid, v4 as uuid } from "uuid";
import { SalesUnit } from "@/repositories/sales-unit/model/SalesUnit";
import { useSalesUnitStore } from "@/repositories/sales-unit/SalesUnitStore";
import { storeToRefs } from "pinia";

const salesUnitStore = useSalesUnitStore();

const props = defineProps<{
  salesUnit: SalesUnit;
}>();

const emit = defineEmits<{
  (e: "onCancel", value: void): void;
  (e: "onCreateSalesUnit", value: SalesUnit): void;
  (e: "onUpdateSalesUnit", value: SalesUnit): void;
  (e: "onDeleteSalesUnit", value: SalesUnit): void;
}>();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();
const salesUnitRef = ref<SalesUnit>(new SalesUnit());
const editMode = ref(false);
const { saving } = storeToRefs(salesUnitStore);

const salesUnitNameRef = ref();
nextTick(() => {
  salesUnitNameRef.value.$el.focus();
});

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onInit = () => {
  salesUnitRef.value = props.salesUnit;
  if (props.salesUnit.id !== emptyUuid) editMode.value = true;
};

onMounted(onInit);

const onCommit = async () => {
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  if (!editMode.value) {
    const newSalesUnit: SalesUnit = {
      id: uuid(),
      name: salesUnitRef.value.name,
      description: salesUnitRef.value.description,
    };

    await salesUnitStore.createSalesUnit(newSalesUnit);
    emit("onCreateSalesUnit", newSalesUnit);
  } else if (editMode.value) {
    await salesUnitStore.updateSalesUnit(salesUnitRef.value);
    emit("onUpdateSalesUnit", salesUnitRef.value);
  }
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const confirmDeleteSalesUnit = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      acceptDeleteSalesUnit();
    },
  });
};

const acceptDeleteSalesUnit = async () => {
  await salesUnitStore.deleteSalesUnit(salesUnitRef.value);
  emit("onDeleteSalesUnit", salesUnitRef.value);
};

const rules = {
  name: {
    required: required,
  },
};

const val = useVuelidate(rules, salesUnitRef);
</script>
<style scoped lang="scss">
.c-delete-button {
  background-color: var(--delete-btn-bg);
  border: none;
}

.c-success-button {
  background-color: var(--success-btn-bg);
  border: none;
}
</style>
