import { httpClient, AuthHeaders } from "@cumulus/http";
import { Attribute } from "@/repositories/attribute/model/Attribute";
import { NewAttribute } from "@/repositories/attribute/model/NewAttribute";

class AttributeApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? `${import.meta.env.VITE_APP_API_PRODUCT as string}/attributes`
        : `${import.meta.env.VITE_APP_API_URL as string}/attributes`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Attribute> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Attribute[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, attribute: NewAttribute): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", attribute);
  }

  public async update(authHeaders: AuthHeaders, attribute: Attribute): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", attribute);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}

export const attributeApi = new AttributeApi();
