<template>
  <div>
    <div class="bg-surface-50 dark:bg-surface-800">
      <div class="p-6">
        <label for="product-accessory" class="font-bold">
          {{ t(`product.accessory.label`) }}
        </label>
      </div>
      <DataTable
        :value="accessoriesComputed"
        class="c-product-accessory-table c-compact-datatable"
        dataKey="id"
        data-testid="product-accessories"
        :autoLayout="true"
        responsiveLayout="stack"
        breakpoint="991px"
        stripedRows
      >
        <template #empty> {{ t(`product.accessory.no-accessory-found`) }}</template>

        <Column field="number" :header="t('product.accessory.number')">
          <template #body="{ data }">
            {{ data.productNumber }}
          </template>
        </Column>

        <Column field="name" :header="t('product.accessory.name')">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>

        <Column class="c-product-edit-accessory-column-remove">
          <template #body="slotProps">
            <PrimeButton
              icon="pi pi-trash"
              class="p-button-text p-button-rounded p-button-secondary"
              @click="onConfirmDelete($event, slotProps.data.id)"
            />
          </template>
        </Column>
      </DataTable>

      <PrimeButton
        type="button"
        class="p-button-text p-button-sm p-6"
        data-testid="add-accessory-btn"
        @click="showDialogAdd = true"
        id="add-accessory-button"
      >
        <i class="c-plus"></i>
        <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
      </PrimeButton>
    </div>

    <AccessoryDialogAdd v-model:showDialogAdd="showDialogAdd" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import AccessoryDialogAdd from "./AccessoryDialogAdd.vue";
import { computed, onMounted, ref, nextTick } from "vue";
import { useProductStore } from "@/repositories/product/ProductStore";
import { storeToRefs } from "pinia";
import { Product } from "@/repositories/product/model/Product";
import { useAccessoriesStore } from "@/stores/ProductAccessoryStore";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const { removeAccessoryById } = useAccessoriesStore();
const { product } = storeToRefs(useAccessoriesStore());
const productStore = useProductStore();
const { products } = storeToRefs(productStore);

const { t } = useI18n();
const showDialogAdd = ref(false);

const accessoriesComputed = computed<Product[]>(() => {
  return products.value.filter((x) => product.value.accessoryProductIds.includes(x.id));
});

onMounted(() => {
  productStore.fetchProducts();
});

const removeAccessory = async (id: string) => {
  removeAccessoryById(id);
  await nextTick();
  document.getElementById("add-accessory-button")?.focus();
};

const onConfirmDelete = (event: Event, id: string) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      removeAccessory(id);
    },
  });
};
</script>

<style scoped lang="scss">
.c-product-accessory-table {
  :deep(.p-datatable-thead > tr > th:first-child) {
    padding-left: 1.5rem;
  }

  :deep(.p-datatable-tbody > tr > td:first-child) {
    padding-left: 1.5rem;
  }

  :deep(.p-datatable-tbody td.c-product-accessory-column-remove) {
    text-align: right;
  }

  :deep(.p-inputnumber-input) {
    max-width: 8rem;
  }
}
.c-product-accessory-column-remove {
  min-width: 3rem;
  padding: 0;

  :deep(.p-column-header-content) {
    float: right;
  }
}
</style>
