<template>
  <div class="c-attributes">
    <AttributeToolbar />

    <Card>
      <template #content>
        <div class="c-attribute-list">
          <div
            class="table-header flex flex-col md:flex-row md:justiify-content-between justify-between flex-wrap mb-4 mt-2"
          >
            <div class="formgroup-inline">
              <span class="p-inputgroup">
                <InputText
                  v-model="filter['global'].value"
                  :placeholder="t('common.search')"
                  type="text"
                  data-testid="input-search-attributes"
                  @focus="selectAllOnFocus"
                  autofocus
                />
                <span class="p-button p-inputgroup-addon bg-transparent border-none z-10 -ml-10"
                  ><i class="pi pi-search text-zinc-500"
                /></span>
              </span>
            </div>
            <div class="flex mt-2 md:mt-0 justify-center">
              <div class="flex items-center justify-center">
                <PrimeButton
                  type="button"
                  class="p-button-text p-button-sm w-full"
                  data-testid="add-attribute-btn"
                  @click="addNewAttribute"
                >
                  <i class="c-plus"></i>
                  <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
                </PrimeButton>
              </div>
            </div>
          </div>
          <DataTable
            :value="attributes"
            dataKey="id"
            :autoLayout="true"
            responsiveLayout="scroll"
            selectionMode="single"
            @row-dblclick="onRowSelect"
            :loading="loading"
            stripedRows
            sortField="name"
            :sortOrder="1"
            :paginator="true"
            :rows="25"
            v-model:filters="filter"
            data-testid="attribute-table"
            class="c-compact-datatable"
          >
            <Column
              field="name"
              :header="t(`product.attribute.name`)"
              :sortable="true"
              filterField="name"
              filterMatchMode="contains"
              headerClass="c-attribute-header w-1/12"
            >
            </Column>
            <Column
              field="values"
              :header="t(`product.attribute.values`)"
              :sortable="true"
              headerClass="c-attribute-header"
            >
              <template #body="{ data }">
                <template v-for="(value, index) in data.values" :key="value">
                  <span data-testid="attribute-value-row">{{ value }}</span>
                  <span v-if="index + 1 < data.values.length">, </span>
                </template>
              </template>
            </Column>
            <Column :header="t('product.attribute.state')">
              <template #body="{ data }">
                <Checkbox :value="data" v-model="activeAttributes" :disabled="true" />
              </template>
            </Column>

            <Column
              :exportable="false"
              style="min-width: 8rem"
              headerClass="c-attribute-header"
              bodyClass="c-attribute-body"
            >
              <template #body="slotProps">
                <PrimeButton
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-text mr-2"
                  @click="editAttribute(slotProps.data)"
                />
              </template>
            </Column>

            <template #empty>{{ t("attribute.no-attributes-found") }} </template>
            <template #loading>{{ t("attribute.loading") }}</template>
          </DataTable>
        </div>
      </template>
    </Card>
  </div>

  <AttributeFormDialog
    v-if="showDialog"
    v-model:showDialog="showDialog"
    :attribute="attribute"
    :attributes="attributes"
    @reloadAttributeList="reloadAttributeList"
    @updateAttribute="updateAttribute"
    @deleteAttribute="deleteAttribute"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref, computed } from "vue";
import { FilterMatchMode } from "@primevue/core/api";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import AttributeFormDialog from "@/attribute/components/AttributeFormDialog.vue";
import { Attribute } from "@/repositories/attribute/model/Attribute";
import { AttributeState } from "@/repositories/attribute/model/AttributeState";
import { useAttribute } from "@/repositories/attribute/AttributeService";
import AttributeToolbar from "../components/AttributeToolbar.vue";

const { t } = useI18n();
const loading = ref(false);
const attributes = ref<Attribute[]>([]);
const attribute = ref<Attribute>(new Attribute());
const showDialog = ref(false);
const filter = ref({ global: { value: "", matchMode: FilterMatchMode.CONTAINS } });
const { getAllAttributes } = useAttribute();

const onRowSelect = (event: DataTableRowDoubleClickEvent) => {
  attribute.value = event.data as Attribute;
  showDialog.value = true;
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const activeAttributes = computed(() => {
  return attributes.value.filter((attribute) => attribute.attributeState === AttributeState.Active);
});

const addNewAttribute = () => {
  attribute.value = new Attribute();
  showDialog.value = true;
};

const updateAttribute = (value: Attribute) => {
  const index = attributes.value.findIndex((p) => p.id === value.id);
  attributes.value.splice(index, 1, value);
};

const loadAttributes = async () => {
  loading.value = true;

  try {
    attributes.value = await getAllAttributes();
  } finally {
    loading.value = false;
  }
};
onMounted(loadAttributes);

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const reloadAttributeList = () => {
  const itemCount = attributes.value.length;
  waitThenReload(itemCount, 0);
};

const waitThenReload = (itemCount: number, retries: number) => {
  if (itemCount === attributes.value.length) {
    timeout(500);
    loadAttributes();

    if (retries < 4) {
      waitThenReload(itemCount, retries + 1);
    }
  }
};

const editAttribute = (value: Attribute) => {
  attribute.value = value;
  showDialog.value = true;
};

const deleteAttribute = (id: string) => {
  const index = attributes.value.findIndex((attribute) => attribute.id === id);
  attributes.value.splice(index, 1);
};
</script>

<style lang="scss" scoped>
.c-attributes {
  margin: var(--default-content-margin);
}
:deep(.p-datatable .p-datatable-thead > tr > th.c-attribute-header) {
  text-align: center;
}

:deep(.p-datatable .p-datatable-tbody > tr > td.c-attribute-body) {
  text-align: center;
}
</style>
