<template>
  <PrimeDialog
    v-model:visible="visible"
    :modal="true"
    :header="t('product.attribute.manage-attributes')"
    class="w-full sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2 max-w-4xl"
  >
    <div class="space-y-4">
      <DataTable v-model:value="localProductAttributes" dataKey="id">
        <Column :header="t('product.attribute.name')" field="attributeId" class="w-[40%]">
          <template #body="{ data, field }">
            <div class="w-full">
              <SelectAttribute
                v-model:selectedAttributeId="data[field]"
                :availableAttributes="filteredAttributes(data.attributeId)"
                @update:selectedAttributeId="resetAttributeValues(data)"
              />
            </div>
          </template>
        </Column>
        <Column :header="t('product.attribute.values')" field="attributeValueIds" class="w-[40%]">
          <template #body="{ data, field }">
            <div class="w-full">
              <SelectAttributeValues
                v-model:selectedValueIds="data[field]"
                :availableValues="getAvailableValues(data.attributeId)"
              />
            </div>
          </template>
        </Column>
        <Column class="w-[20%]">
          <template #body="{ index }">
            <div class="flex justify-center">
              <PrimeButton
                icon="pi pi-trash"
                severity="danger"
                raised
                @click="onConfirmDelete($event, index)"
                :data-testid="`remove-attribute-${index}`"
              />
            </div>
          </template>
        </Column>
        <template #empty>
          {{ t("product.attribute.no-attributes-found") }}
        </template>
      </DataTable>

      <div class="flex justify-start">
        <PrimeButton
          v-if="maximumAttributesAdded"
          text
          size="small"
          v-tooltip="{ value: t('product.attribute.no-attributes-available') }"
          icon="pi pi-plus c-disabled"
          :label="t('common.add')"
        />
        <PrimeButton
          v-else
          type="button"
          text
          size="small"
          @click="addNewAttribute"
          data-testid="add-attribute-btn"
          icon="pi pi-plus"
          :label="t('common.add')"
        />
      </div>
    </div>
    <template #footer>
      <div class="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
        <PrimeButton type="button" @click="onCancel" class="p-button-text w-full sm:w-auto">
          {{ t("common.cancel") }}
        </PrimeButton>
        <PrimeButton
          type="button"
          @click="onSave"
          class="p-button-primary w-full sm:w-auto"
          data-testid="save-attributes-btn"
        >
          {{ t("common.save") }}
        </PrimeButton>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { AttributeByLanguageIso } from "@/models/attribute/AttributeByLanguageIso";
import SelectAttribute from "./SelectAttribute.vue";
import SelectAttributeValues from "./SelectAttributeValues.vue";
import cloneDeep from "lodash.clonedeep";
import { useConfirm } from "primevue/useconfirm";
import useValidate from "@vuelidate/core";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";

const visible = defineModel<boolean>("visible", { required: true });

const props = defineProps<{
  productAttributes: ProductAttribute[];
  attributes: AttributeByLanguageIso[];
}>();

const emit = defineEmits<{
  (e: "update:productAttributes", value: ProductAttribute[]): void;
}>();

const { t } = useI18n();
const confirm = useConfirm();
const val = useValidate();
const toast = useCumulusToast(useToast());

const localProductAttributes = ref<ProductAttribute[]>([]);

watch(
  () => props.productAttributes,
  (newValue) => {
    localProductAttributes.value = cloneDeep(newValue);
  },
  { immediate: true },
);

const filteredAttributes = (attributeId: string) => {
  return props.attributes.filter((attribute) => {
    return !localProductAttributes.value.some((productAttribute) => {
      return productAttribute.attributeId === attribute.id && attributeId !== attribute.id;
    });
  });
};

const getAvailableValues = (attributeId: string) => {
  const attribute = props.attributes.find((attribute) => attribute.id === attributeId);
  return attribute ? attribute.values : [];
};

const maximumAttributesAdded = computed<boolean>(() => {
  return localProductAttributes.value.length >= props.attributes.length;
});

const addNewAttribute = () => {
  localProductAttributes.value.push({
    attributeId: "",
    attributeValueIds: [],
  });
};

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }
  emit("update:productAttributes", localProductAttributes.value);
  visible.value = false;
};

const onCancel = () => {
  localProductAttributes.value = cloneDeep(props.productAttributes);
  visible.value = false;
};

const resetAttributeValues = (data: ProductAttribute) => {
  data.attributeValueIds = [];
};

const onConfirmDelete = (event: Event, index: number) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      removeAttribute(index);
    },
  });
};

const removeAttribute = (index: number) => {
  localProductAttributes.value.splice(index, 1);
};
</script>
