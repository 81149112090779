<template>
  <DataTable
    ref="manufacturerTableRef"
    :value="manufacturers"
    scrollHeight="flex"
    class="c-datatable-keyboard c-compact-datatable"
    dataKey="id"
    selectionMode="single"
    @row-select="onRowSelect"
    :loading="loadingManufacturers"
    :rowHover="true"
    v-model:filters="filtersManufacturer"
    data-testid="manufacturer-table"
    sortField="name"
    :sortOrder="1"
    stripedRows
    autoLayout
    :paginator="true"
    :rows="10"
  >
    <template #header>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-search" />
        <InputText
          id="manufacturerSearchInput"
          ref="searchInputRef"
          v-model="filtersManufacturer['global'].value"
          :placeholder="t('common.search')"
          @focus="selectAllOnFocus"
          @keyup.enter="searchInputEnterKey"
          @keypress.tab="focusManufactureTable"
          @keydown.down="focusManufactureTable"
          class="w-full"
          autocomplete="off"
          data-testid="manufacturer-search"
        />
      </span>
    </template>

    <Column :style="{ width: '1.875rem' }" header="">
      <template #body="{ data }">
        <img v-if="urlIsSet(data?.logo?.url)" :src="resizeImage(data?.logo?.url, 30, 30)" />
      </template>
    </Column>
    <Column field="name" :header="t('product.manufacturer.name')">
      <template #body="{ data }">
        <span data-testid="manufacturer-row">{{ data.name }}</span>
      </template>
    </Column>
    <Column :style="{ width: '1.875rem' }">
      <template #body="{ data }">
        <PrimeButton
          :id="data.name.toLowerCase() + '-edit'"
          type="button"
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          @click="editManufacturer(data)"
        ></PrimeButton>
      </template>
    </Column>

    <template #loading>{{ t("product.manufacturer.loading") }}</template>

    <template #empty>
      <div id="empty-m-filter" ref="emptyFilterRef">
        {{ t("product.manufacturer.no-manufacturer-found") }}
      </div>
    </template>

    <template #footer>
      <div v-if="!loadingManufacturers" class="flex justify-end" style="margin-bottom: -1.2rem">
        <PrimeButton
          type="button"
          :label="t('common.create')"
          class="p-button-info"
          @click="$emit('createNewManufacturer')"
          data-testid="manufacturer-create-new"
        ></PrimeButton>
      </div>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { DataTableRowSelectEvent } from "primevue/datatable";
import { FilterMatchMode, FilterOperator } from "@primevue/core/api";
import { useImageService } from "@/repositories/image/ImageService";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";

const { t } = useI18n();
const { resizeImage } = useImageService();

defineProps<{
  loadingManufacturers: boolean;
  manufacturers: Manufacturer[];
}>();

const emit = defineEmits<{
  (e: "manufacturerSelected", value: Manufacturer): void;
  (e: "editManufacturer", value: Manufacturer): void;
  (e: "createNewManufacturer", value: void): void;
  (e: "hideOverlayPanel", value: void): void;
}>();

const manufacturerTableRef = ref();
const emptyFilterRef = ref(null);

const focusManufactureTable = (event: Event) => {
  event.stopPropagation();
  manufacturerTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const searchInputEnterKey = (event: Event) => {
  event.stopPropagation();
  if (emptyFilterRef.value === null) {
    focusManufactureTable(event);
  } else {
    emit("createNewManufacturer");
  }
};

const editManufacturer = (manufacturer: Manufacturer) => {
  emit("editManufacturer", manufacturer);
};

const onRowSelect = (event: DataTableRowSelectEvent) => {
  emit("manufacturerSelected", event.data as Manufacturer);
  emit("hideOverlayPanel");
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const urlIsSet = (url: string) => {
  return url !== undefined && url !== null && url !== "about:blank" && url.length > 0;
};

const filtersManufacturer = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  name: { operator: FilterOperator.AND, constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }] },
  description: { operator: FilterOperator.AND, constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }] },
});

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});
</script>

<style lang="scss">
.manufacture-panel {
  .c-no-search-result {
    color: var(--pink-700);
  }

  .p-datatable-header {
    padding: 0;
    margin-bottom: 0.5rem;

    .p-inputtext {
      border: none;
      border-bottom: 2px solid var(--primary-color);
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-color: var(--search-dropdown-border-color);
      }
    }
    .p-input-icon-left > i:first-of-type {
      color: var(--search-dropdown-border-color);
      font-weight: bolder;
    }
  }

  .p-datatable-footer {
    padding: 0.75rem 0;
    float: right;
  }

  .p-datatable-wrapper {
    border: var(--data-table-border);
    border-radius: var(--border-radius);
    background: var(--data-table-bg);
  }
}
</style>
