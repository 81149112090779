{
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "minLength": "Minimum length is {min}",
    "exists": "{property} already exists"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "created": "Created",
    "add": "Add",
    "save": "Save",
    "cancel": "Cancel",
    "ignore": "Ignore",
    "search": "Search",
    "clear": "Clear",
    "yes": "Yes",
    "no": "No",
    "draft": "Draft",
    "delete": "Delete",
    "close": "Close",
    "create": "Create",
    "use": "Use",
    "options": "Options",
    "preview": "Preview",
    "reload": "Reload",
    "column-chooser": "Column chooser",
    "reset": "Reset columns",
    "addresses": "Addresses",
    "recommended": "Recommended",
    "error": "An error occured",
    "general": "General",
    "product-info": "Product Information",
    "warehouse-and-client": "Warehouse and client",
    "prices-and-fees": "Prices and Fees",
    "attributes-and-connections": "Attributes and product connections",
    "current-page-template": "Showing {first} to {last} of {totalRecords} products",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text": "You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard": "Discard",
    "back": "Back",
    "delete-confirm": "Do you want to delete this item?",
    "save-tooltip": "No changes",
    "delete-confirm-all": "Are you sure you want to delete all documents?",
    "languages": {
      "en": "English",
      "no": "Norwegian"
    },
    "validation-error": {
      "summary": "Validation failed",
      "detail": "Correct all red fields and try again"
    },
    "error-detail": {
      "not-found": "Not found",
      "unknown": "Unknown error",
      "server-error": "Server error",
      "bad-request": "Bad request",
      "error-occured": "An error occurred"
    },
    "freight-agreements": {
      "fixed-shipping": "Fixed shipping"
    }
  },
  "attribute": {
    "name": "Name",
    "value": "Value",
    "description": "Description",
    "color-code": "Color code (hex)",
    "values": "Values",
    "state": "Active",
    "loading": "Loading attributes...",
    "no-attributes-found": "No attributes found",
    "load-failed": "Loading attributes failes, reason: {errorReason}",
    "label": "Attributes",
    "type": "Type",
    "add-attribute": "Add attribute",
    "add-value": "Add value",
    "edit-attribute": "Edit attribute",
    "types": {
      "standard": "Standard",
      "color": "Color"
    },
    "error": {
      "duplicate": "Duplicate values found in the list",
      "already-exists": "Attribute already exists",
      "attribute-active": "Attribute is active, update to inactive and try deleting again",
      "at-least-one-value": "At least one value is required",
      "fill-all-values": "Fill all values in the list"
    },
    "toast": {
      "add-success": {
        "summary": "Attribute is added!"
      },
      "update-success": {
        "summary": "Attribute is updated!"
      },
      "delete-success": {
        "summary": "Attribute is deleted!"
      }
    }
  },
  "product": {
    "search-product": "Search Product",
    "add-product":"Add Product",
    "edit-product":"Edit Product",
    "context-menu": {
      "open": "Open"
    },
    "title-add": "New product",
    "title-edit": "Product",
    "created": "Created:",
    "warehouse": {
      "name": "Name",
      "label": "Warehouses",
      "title":"Warehouse",
      "no-warehouse-found": "No new warehouses found",
      "dialog-header": "Select Warehouses",
      "cost-price": "Cost price"
    },
    "active-error": {
      "header": "Product is active",
      "detail": "Try set to inactive and try again"
    },
    "domain-link": "Domain link",
    "product-links": "Product links",
    "fees": "Fees",
    "excise-duties": "Excise duties",
    "number": "Product number",
    "number-input": "Product number, or leave blank to generate",
    "gti-number": "GTIN(EAN)",
    "name": "Product name",
    "description": "Product description",
    "info": "Product info",
    "open-editor": "Open editor",
    "close-editor": "Close editor",
    "webshop-active": "Active on webshop",
    "dimension-weight": "Dimension and weight",
    "tax-rate": "Tax rate",  
    "download-link-missing": "Failed to get document, please try again later",
    "upload-started": "Upload started",
    "upload-started-details": "Uploading documents, please wait...",
    "documents": {
      "header": "Documents",
      "name": "Document name",
      "type": "Document type",
      "title": "Title",
      "description": "Description",
      "no-description": "No description",
      "unnamed-document": "Unnamed Document",
      "no-documents-found": "No document agreements found for this supplier",
      "choose-label": "Add documents",
      "drag-and-drop": "Drag and drop new documents here",
      "upload": "Upload document",
      "manage": "Manage documents",
      "select": "Select document",
      "clear": "Clear documents",
      "supported-formats": "Supported formats: DOC, DOCX, PDF, TXT, RTF (Max size: 10MB)",
      "manage-product-documents": "Manage Product Documents"
    },
    "account-group": {
      "label": "Account group",
      "name": "Name",
      "no-account-groups-found": "No account groups found",
      "create-account-group": "Create account group",
      "update-account-group": "Update account group",
      "account-group-saved": "Account group saved",
      "account-group-deleted": "Account group deleted",
      "standard-cant-be-altered": "Standard account group can't be altered"
    },
    "header": {
      "add-product": "Add product",
      "edit-product": "Edit product"
    },
    "search-header": {
      "products": "Products",
      "add-product": "Add product",
      "open-product": "Open",
      "no-product-selected": "No product selected. Select a product and try again.",
      "refresh-product-list": "Reload"
    },
    "dimension": {
      "label": "Dimensions:",
      "length": "Length",
      "width": "Width",
      "height": "Height",
      "unit": "Unit",
      "weight": "Weight"
    },
    "hierarchy": {
      "label": "Product hierarchy",
      "placeholder": "Select a product hierarchy"
    },
    "type": {
      "label": "Product type",
      "merchandise": "Merchandise",
      "servicegoods": "Service goods"
    },
    "status": {
      "label": "Status",
      "active": "Active",
      "inactive": "Inactive",
      "discontinued": "Discontinued"
    },
    "sales-unit": {
      "label": "Sales unit",
      "not-found": "No sales units found",
      "server-error": "Failed to load sales units",
      "select": "Select a sales unit...",
      "search": "Search for a sales unit...",
      "loading": "Loading sales units...",
      "success-create": {
        "summary": "Sales unit sucessfully added!",
        "detail": "Now adding sales unit to product.."
      },
      "success-update": {
        "summary": "Sales unit sucessfully updated!",
        "detail": "For sales unit {name}"
      },
      "success-delete": {
        "summary": "Sales unit sucessfully deleted!",
        "detail": "For Sales unit {name}"
      },
      "name": "Name",
      "description": "Description"
    },
    "media": {
      "label": "Media",
      "dialog-header": "Add media",
      "no-media-found": "No media found",
      "description": "Description",
      "media-type": "Media type",
      "uri": "Uri",
      "preview": "Preview",
      "title": "Title"
    },
    "image": {
      "label": "Images",
      "invalid-file-size": "{frist}: Invalid file size!, file size should be smaller than {last}",
      "invalid-file-limit": "Maximum number of files exceeded, limit is {frist} at most.",
      "choose-label": "Add images",
      "drag-and-drop": "Drag and drop new images here",
      "upload": "Upload image",
      "manage": "Manage images",
      "description": "Description",
      "title": "Title",
      "no-images-found": "No images found",
      "select": "Add image",
      "clear": "Clear images",
      "supported-formats": "Supported formats: JPEG, PNG, WebP (Max size: 10MB)",
      "unnamed-image": "Unnamed image",
      "no-description": "No description",
      "image-name": "Image name",
      "image-description": "Image description"
    },
    "video": {
      "label": "Videos",
      "add": "Add",
      "update": "Update",
      "name": "Name",
      "uri": "Uri",
      "description": "Description",
      "title": "Title",
      "no-videos-found": "No videos found",
      "preview": "Preview",
      "no-preview-available": "No preview available"
    },
    "supplier": {
      "new": "New supplier",
      "error": "At least one supplier is required",
      "label": "Suppliers",
      "no-suppliers-found": "No suppliers found.",
      "name": "Name",
      "product-number": "Supplier's product number",
      "currency": "Currency",
      "cost-price": "Cost price",
      "freight": "Freight",
      "customs": "Customs",
      "purchase-price": "Purchase price",
      "replacement-cost": "Replacement cost",
      "calculated-cost": "Calculated cost",
      "default-supplier": "Main",
      "dialog-header": "Select supplier",
      "estimated-delivery": "Estimated delivery"
    },
    "price": {
      "new": "New price group",
      "loading": "Loading price groups..",
      "label": "Price",
      "no-price-found": "No price group found.",
      "price-group-name": "Price group",
      "listprice": "List price",
      "cost-price": "Cost price",
      "gross-margin": "Gross margin",
      "contribution-margin": "CM(%)",
      "listprice-incl-vat": "Listprice incl. VAT",
      "listprice-excl-vat": "Listprice excl. VAT",
      "currency": "Currency",
      "toast": {
        "error": {
          "summary": "Failed to load price information",
          "detail": "Using default price values"
        }
      },
      "calculate-error": {
        "summary": "Failed to calculate price",
        "detail": "Please try again later"
      }
    },
    "alternative": {
      "label": "Alternatives",
      "not-found": "No alternatives found.",
      "number": "Product number",
      "name": "Name",
      "description": "Description",
      "dialog-header": "Select alternative products"
    },
    "accessory": {
      "label": "Accessories",
      "no-accessory-found": "No accessories found.",
      "number": "Product number",
      "name": "Name",
      "description": "Description",
      "dialog-header": "Select accessory products"
    },
    "attribute": {
      "attribute": "Attribute",
      "attribute-values": "Attribute values",
      "label": "Attributes",
      "loading": "Loading attributes...",
      "no-attributes-found": "No attributes found | No values found for attribute",
      "select": "Select an attribute | Select value",
      "name": "Name",
      "description": "Description",
      "values": "Values",
      "value": "Value",
      "state": "Active",
      "manage-attributes": "Manage attributes",
      "no-attributes-available": "No attributes available"
    },
    "client": {
      "error": "At least one client is required",
      "dialog-header": "Select clients",
      "name": "Name",
      "label": "Clients",
      "no-client-found": "No clients found."
    },
    "manufacturer": {
      "label": "Manufacturer",
      "loading": "Loading manufacturers..",
      "no-manufacturer-found": "No manufacturer found.",
      "active": "Active",
      "inactive": "Inactive",
      "add-logo": "Add logo",
      "select": "Select manufacturer",
      "name": "Name",
      "description": "Description",
      "website-url": "Website url",
      "status": "Status",
      "logo": "Logo",
      "toast": {
        "success-add": {
          "summary": "New manufacturer was added!",
          "detail": "Manufacturer name {name}"
        },
        "success-update": {
          "summary": "Manufacturer was saved!",
          "detail": "Manufacturer name {name}"
        },
        "success-deleted": {
          "summary": "Manufacturer was deleted!",
          "detail": "Manufacturer name {name}"
        },
        "error": {
          "summary": "Could not create manufacturer",
          "detail": "Please try again later"
        }
      }
    },
    "tax": {
      "dialog-header": "Select tax",
      "edit-tax": "Edit tax",
      "default-selected": "Default Selected",
      "default": "Default"
    },
    "toast": {
      "success-add": {
        "summary": "New product was added!",
        "detail": "Product name {name}"
      },
      "success-delete": {
        "summary": "Product was deleted!",
        "detail": "Product name {name}"
      },
      "success-edit": {
        "summary": "Product was saved!",
        "detail": "Product name {name}"
      },
      "warn": {
        "summary": "Validation failed",
        "detail": "Correct all red fields and try again"
      },
      "error-delete": {
        "summary": "Could not delete product",
        "detail": "Please try again later"
      },
      "error-edit": {
        "summary": "Failed to save product",
        "detail": "Server error"
      },
      "reload": {
        "summary": "Reload product"
      },
      "open-product-failed": "Failed to open product"
    },
    "dimension-unit": {
      "m": "m",
      "dm": "dm",
      "cm": "cm",
      "mm": "mm"
    },
    "weight-unit": {
      "t": "t",
      "kg": "kg",
      "g": "g",
      "mg": "mg"
    },
    "list": {
      "title": "Products",
      "no-items": "No products found",
      "loading-items": "Loading products...",
      "column": {
        "no": "Product no.",
        "active-on-webshop": "Active on webshop",
        "name": "Name"
      }
    },
    "search": {
      "search-label": "Product search:",
      "total-hits": "No products found | 1 product found | {totalHits} products found",
      "add-btn": "New product",
      "advanced-btn": "Advanced",
      "select-all": "Select all",
      "delete-selected": "Delete selected",
      "edit-columns": "Customize columns",
      "header-image": "Main image",
      "header-product-number": "Product no.",
      "header-gtin": "GTIN",
      "header-name": "Name",
      "header-description": "Description",
      "header-product-info": "Product info",
      "search-error": "Search failed",
      "loading-items": "Loading products...",
      "product-status": {
        "active": "Active",
        "inactive": "Inactive",
        "discontinued": "Discontinued"
      }
    },
    "search-filters": {
      "filter": "Filter",
      "header": "Product filter",
      "clear": "Clear filter",
      "show-filter": "Show filter",
      "hide-filter": "Hide filter",
      "status": "Status",
      "manufacturer": "Manufacturer",
      "product": "Product"
    },
    "panel":{
      "close-panels": "Close tabs",
      "open-panels":"Open tabs"
    }
  }
}