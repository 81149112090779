<template>
  <CumulusRightSidebar v-model:showSidebar="showSidebarComputed" :enableDragover="false" :sidebarWidth="260">
    <template v-slot:header>
      <div class="c-filter-header-container">
        <i class="pi pi-sliders-h"></i>
        <h1>{{ t("product.search-filters.filter") }}</h1>
      </div>
    </template>
    <template v-slot:body>
      <h2 class="text-center">{{ t("product.search-filters.status") }}</h2>

      <div
        v-for="(value, index) in allStatusesComputed"
        :key="index"
        class="flex justify-between relative items-center p-2 rounded-md"
        :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
      >
        <label :for="'product-status-' + value.status.toLowerCase()">{{
          t(`product.search.product-status.${value.status.toLowerCase()}`)
        }}</label>

        <div class="ml-auto">
          <Checkbox
            v-model="statusesComputed"
            :value="value.status"
            :inputId="'product-status-' + value.status.toLowerCase()"
            :data-testid="'product-status-' + value.status.toLowerCase()"
          />
        </div>
      </div>
      <hr class="c-seperator-line" />

      <h2 class="text-center">{{ t("product.search-filters.manufacturer") }}</h2>
      <MultiSelect
        id="inventory-manufacturers"
        data-testid="inventory-manufacturers"
        v-model="selectedManufacturerComputed"
        :placeholder="t('placeholder.select', { property: t('product.search-filters.manufacturer').toLowerCase() })"
        optionLabel="name"
        optionValue="id"
        :options="manufacturers"
        display="chip"
        class="w-full field"
        :filter="true"
        :loading="loading"
        pt:list:data-testid="inventory-manufacturers-list"
      />
    </template>
  </CumulusRightSidebar>
</template>
<script setup lang="ts">
import { ProductSearchFilters } from "@/repositories/search/model/ProductSearchFilters";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ProductStatus } from "@/repositories/product/model/ProductStatus";
import { CumulusRightSidebar } from "@cumulus/components";

const { t } = useI18n();

const props = defineProps<{
  showSidebar: boolean;
  filters: ProductSearchFilters;
  manufacturers: Manufacturer[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (event: "update:showSidebar", value: boolean): void;
  (event: "update:filters", value: ProductSearchFilters): void;
}>();

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(ProductStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const statusesComputed = computed<ProductStatus[]>({
  get: () => props.filters.productStatuses,
  set: (value) => {
    const filters = { ...props.filters, productStatuses: value };
    emit("update:filters", filters);
  },
});

const showSidebarComputed = computed<boolean>({
  get: () => props.showSidebar,
  set: (value) => emit("update:showSidebar", value),
});

const selectedManufacturerComputed = computed<string[]>({
  get: () => props.filters.manufacturers,
  set: (value) => {
    const filters = { ...props.filters, manufacturers: value };
    emit("update:filters", filters);
  },
});
</script>
<style scoped lang="scss">
.c-seperator-line {
  border: 1px solid var(--seperator-line);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.c-list-row-bg {
  background-color: var(--list-row-bg);
}

.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}

h2,
label {
  font-family: var(--font-family);
}
h2 {
  font-size: 14px;
}

.c-filter-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0rem;
  background-color: #fff;
  z-index: 1;
  padding: 0.5rem;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.c-filter-header-container i {
  margin-right: 0.5rem;
}
.c-product-numbers {
  width: 100%;
}
.c-product-numbers :deep(ul) {
  width: 100%;
}
</style>
