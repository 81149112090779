<template>
  <Popover
    ref="accountGroupPanelRef"
    appendTo="body"
    :dismissable="true"
    id="account_group_overlay_panel"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '400px' }"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <AccountGroupList
      :accountGroups="accountGroups"
      :loading="false"
      :pageSize="10"
      @accountGroupSelected="onAccountGroupSelected"
      @editAccountGroupClicked="onEditAccountGroup"
      @createNewAccountGroup="onCreateNewAccountGroup"
    />
    <div class="formgrid grid grid-cols-12 gap-4 mt-4">
      <div class="col-span-6 lg:col-span-6 flex flex-col">
        <div class="flex justify-start">
          <PrimeButton
            type="button"
            :label="t('common.close')"
            class="c-dialog-default-button"
            @click="hideOverlayPanel"
            data-testid="account-group-close-panel"
          ></PrimeButton>
        </div>
      </div>
      <div class="col-span-6 lg:col-span-6 flex flex-col">
        <div class="flex justify-end">
          <PrimeButton
            type="button"
            :label="t('common.create')"
            class="c-dialog-default-button"
            data-testid="account-group-create-new"
            @click="onCreateNewAccountGroup"
          ></PrimeButton>
        </div>
      </div>
    </div>
  </Popover>

  <AccountGroupModal
    v-if="visibleDialog"
    v-model:showDialog="visibleDialog"
    :selectedAccountGroup="selectedAccountGroup"
    @accountGroupCreated="onAccountGroupCreated"
  />
</template>

<script setup lang="ts">
import { AccountGroup } from "@/product/models/AccountGroup";
import { ref } from "vue";
import AccountGroupList from "./AccountGroupList.vue";
import { useI18n } from "vue-i18n";
import AccountGroupModal from "./AccountGroupModal.vue";

defineProps<{
  accountGroups: AccountGroup[];
}>();

const emit = defineEmits<{
  (e: "accountGroupCreated", value: AccountGroup): void;
  (e: "accountGroupSelected", value: AccountGroup): void;
}>();

const { t } = useI18n();
const accountGroupPanelRef = ref();
const selectedAccountGroup = ref<AccountGroup>();
const visibleDialog = ref(false);

const hideOverlayPanel = () => {
  accountGroupPanelRef.value.hide();
};

const onCreateNewAccountGroup = () => {
  selectedAccountGroup.value = new AccountGroup();
  visibleDialog.value = true;
};

const onAccountGroupSelected = (accountGroup: AccountGroup) => {
  emit("accountGroupSelected", accountGroup);
  hideOverlayPanel();
};

const onAccountGroupCreated = (accountGroup: AccountGroup) => {
  emit("accountGroupCreated", accountGroup);
  hideOverlayPanel();
};

const onEditAccountGroup = (accountGroup: AccountGroup) => {
  selectedAccountGroup.value = accountGroup;
  visibleDialog.value = true;
};

const toggle = (event: Event) => {
  accountGroupPanelRef.value.toggle(event);
};

defineExpose({ toggle: toggle });
</script>
