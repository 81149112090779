<template>
  <span class="p-inputgroup">
    <InputText
      v-model="filter['global'].value"
      :placeholder="t('common.search')"
      type="text"
      data-testid="attribute-search-values"
      @focus="selectAllOnFocus"
      @keydown.enter="addValueToAttribute"
      autofocus
    />
    <span class="p-inputgroup-addon">
      <i class="pi pi-search" />
    </span>
  </span>
  <DataTable
    :value="attributeOptions"
    v-model:selection="selectedAttributeValuesComputed"
    :loading="loading"
    class="c-compact-datatable mt-4"
    data-testid="attribute-value-table"
    :autoLayout="true"
    :rowHover="true"
    responsiveLayout="stack"
    breakpoint="991px"
    selectionMode="multiple"
    v-model:filters="filter"
    :metaKeySelection="false"
    stripedRows
  >
    <template #empty>{{ t("product.attribute.no-attributes-found", 2) }} </template>
    <template #loading>{{ t("product.attribute.loading") }}</template>

    <Column field="value" filterField="value" filterMatchMode="contains" :header="t(`product.attribute.values`)">
      <template #body="{ data }">
        <span data-testid="attribute-value-row">{{ data.value }}</span>
      </template>
    </Column>
    <Column selectionMode="multiple" />
  </DataTable>
</template>

<script setup lang="ts">
import { useAttribute } from "@/repositories/attribute/AttributeService";
import { FilterMatchMode } from "@primevue/core/api";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  selectedAttributeValues: string[];
  selectedAttributeId: string;
}>();

const emit = defineEmits<{
  (e: "update:selectedAttributeValues", value: string[]): void;
}>();

const { t } = useI18n();
const filter = ref({ global: { value: "", matchMode: FilterMatchMode.CONTAINS } });
const loading = ref(false);
const attributeOptions = ref<Record<string, string>[]>([]);
const { getAttributeById } = useAttribute();

const addValueToAttribute = () => {
  alert("Under construction");
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const selectedAttributeValuesComputed = computed({
  get() {
    return props.selectedAttributeValues
      ? props.selectedAttributeValues.map((value) => {
          return {
            value: value,
            name: value,
          } as Record<string, string>;
        })
      : [];
  },
  set(value: Record<string, string>[]) {
    const newValue = value.map((item) => {
      return item.value;
    });
    emit("update:selectedAttributeValues", newValue);
  },
});

const fetchAttributeValues = async () => {
  try {
    loading.value = true;

    const attribute = await getAttributeById(props.selectedAttributeId);

    if (attribute) {
      attributeOptions.value = attribute.values.map((value: string) => {
        return {
          value: value,
          name: value,
        };
      });
    }
  } finally {
    loading.value = false;
  }
};
onMounted(fetchAttributeValues);
</script>
