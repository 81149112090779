<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t(`product.supplier.dialog-header`)"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @keydown.esc.stop="visible = false"
    @update:visible="onUpdateVisible"
  >
    <div class="c-supplier-add-dialog">
      <DataTable
        :value="filteredSuppliers"
        class="c-compact-datatable"
        dataKey="id"
        filterDisplay="menu"
        :autoLayout="true"
        responsiveLayout="scroll"
        selectionMode="single"
        @row-select="onRowSelect"
        :globalFilterFields="['name', 'phoneNumber']"
        stripedRows
      >
        <template #empty>{{ t(`product.supplier.no-suppliers-found`) }}</template>

        <Column field="name" :header="t(`product.supplier.name`)" :sortable="true">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>

        <Column field="currency" :header="t(`product.supplier.currency`)" :sortable="true">
          <template #body="{ data }">
            {{ data.currency.name }}
          </template>
        </Column>
      </DataTable>
    </div>

    <template #footer>
      <PrimeButton
        :label="t('common.cancel')"
        data-testid="cancel-btn"
        @click="visible = false"
        class="c-dialog-default-button"
        autofocus
        severity="cancel"
        text
        :pt="{
          label: 'font-bold -mx-6',
        }"
        icon="pi pi-times"
      />
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { Supplier } from "@/repositories/supplier/model/Supplier";
import { SupplierPrice } from "@/repositories/product/model/SupplierPrice";
import { DataTableRowSelectEvent } from "primevue/datatable";

const { t } = useI18n();

const props = defineProps<{
  supplierPrices: SupplierPrice[];
  showDialogAdd: boolean;
  suppliers: Supplier[];
}>();

const emit = defineEmits<{
  (e: "addSupplierPrice", value: SupplierPrice): void;
  (e: "update:showDialogAdd", value: boolean): void;
}>();

const visible = computed({
  get: () => props.showDialogAdd,
  set: (value) => emit("update:showDialogAdd", value),
});

const suppliers = ref<Array<Supplier>>(props.suppliers);
const onUpdateVisible = (value: boolean) => {
  if (value == false) document.getElementById(`add-supplier-button`)?.focus();
};

const filteredSuppliers = computed(() => {
  const result = suppliers.value.filter((supplier: Supplier) => {
    const index = props.supplierPrices?.findIndex((s: SupplierPrice) => s.supplierId === supplier.id);
    return index === -1;
  });
  return result;
});

const onRowSelect = (event: DataTableRowSelectEvent) => {
  const supplier = event.data as Supplier;
  const supplierPrice = new SupplierPrice(supplier.id, supplier.currency.name, supplier.estimatedDeliveryDays);
  emit("addSupplierPrice", supplierPrice);
  visible.value = false;
};
</script>

<style lang="scss">
.c-supplier-add-dialog {
  padding: 0 1.5rem;
  border: none;
}
</style>
