<template>
  <div class="formgrid grid grid-cols-12 gap-4 items-baseline p-2 pb-0">
    <div class="field col-span-12 md:col-span-12 lg:col-span-5 flex flex-col">
      <AttributeButton
        :attributeType="AttributeType.Name"
        :attribute="attributeComputed"
        @showAttributePanel="showAttributePanel($event, AttributeType.Name)"
        :label="t(`product.attribute.name`)"
        :id="`add-product-attribute-name-${index}`"
      />
    </div>
    <div class="field col-span-12 md:col-span-12 lg:col-span-6 flex flex-col">
      <AttributeButton
        :attributeType="AttributeType.Values"
        :attribute="attributeComputed"
        @showAttributePanel="showAttributePanel($event, AttributeType.Values)"
        :label="t(`product.attribute.values`)"
        :selectLabel="t('product.attribute.select', 2)"
        :id="`add-product-attribute-values-${index}`"
      />
      <small id="attribute-value-help" data-testid="attribute-value-error" class="p-error" v-if="val.$error">{{
        val.$errors[0].$message
      }}</small>
    </div>
    <div class="col-span-12 md:col-span-12 lg:col-span-1 flex flex-col">
      <PrimeButton
        id="attribute-delete"
        data-testid="attribute-delete"
        icon="pi pi-trash"
        class="p-button-rounded p-button-secondary p-button-text m-auto"
        @click="onConfirmDelete($event, attribute)"
      />
    </div>
  </div>

  <AttributePanel
    ref="attributePanelRef"
    v-model:selectedAttributeValues="attributeComputed.values"
    :selectedAttribute="attributeComputed"
    :attributeType="attributeType"
    :productAttributes="productAttributes"
    @onAttributeSelect="onAttributeSelect"
  />
</template>

<script setup lang="ts">
import { Attribute } from "@/repositories/attribute/model/Attribute";
import { AttributeType } from "@/repositories/attribute/model/AttributeType";
import useVuelidate from "@vuelidate/core";
import { ref, computed } from "vue";
import AttributePanel from "./AttributePanel.vue";
import AttributeButton from "./AttributeButton.vue";
import { useI18n } from "vue-i18n";
import { requiredIf } from "@vuelidate/validators";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const props = defineProps<{
  attribute: ProductAttribute;
  productAttributes: ProductAttribute[];
  index?: number;
}>();

const emit = defineEmits<{
  (e: "update:attribute", value: ProductAttribute): void;
  (e: "onAttributeDelete", value: ProductAttribute): void;
}>();

const { t } = useI18n();
const attributePanelRef = ref();
const attributeType = ref<AttributeType>(AttributeType.Name);

const attributeComputed = computed<ProductAttribute>({
  get: () => (props.attribute ? props.attribute : new ProductAttribute()),
  set: (value) => {
    emit("update:attribute", value);
  },
});

const onAttributeSelect = (selectedAttribute: Attribute) => {
  const attribute: ProductAttribute = {
    id: selectedAttribute.id,
    name: selectedAttribute.name,
    values: [],
    attributeState: selectedAttribute.attributeState,
  };
  emit("update:attribute", attribute);
};

const showAttributePanel = (event: Event, type: AttributeType) => {
  attributeType.value = type;
  attributePanelRef.value.toggle(event);
};

const rules = {
  values: {
    required: requiredIf(() => attributeComputed.value.name !== ""),
  },
};

const val = useVuelidate(rules, attributeComputed);
const onConfirmDelete = (event: Event, attribute: ProductAttribute) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("onAttributeDelete", attribute);
    },
  });
};
</script>
