<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('product-group-name-dialog.add-dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @keydown.esc="closeDialog"
    class="c-add-product-group-name-dialog"
    data-testid="add-product-group-name-dialog"
  >
    <div>
      <DataTable
        :value="newProductGroupName.names"
        class="c-add-product-group-name-table"
        dataKey="iso"
        :autoLayout="true"
        responsiveLayout="scroll"
        selectionMode="single"
        editMode="row"
        size="small"
        data-testid="add-product-group-name-list"
      >
        <Column field="iso" :header="t(`product-group-name-dialog.header-language`)">
          <template #body="{ data }">
            {{ t("product-group-name-dialog.language." + (data.languageIso as string).toLowerCase()) }}
          </template>
        </Column>

        <Column field="text" :header="t(`product-group-name-dialog.header-name`)" class="c-td-name">
          <template #body="{ data, field, index }">
            <InputText
              v-model="data[field]"
              :class="{ 'p-invalid': val.$error && (val.$errors[0].$message as string)[index].toString() }"
              autofocus="true"
              :data-testid="`input-name-${(data.languageIso as string).toLowerCase()}`"
              @blur="copyFirstGroupNameToAllLanguage(data[field], index)"
            />
            <div>
              <small
                class="p-error"
                v-if="val.$error"
                :data-testid="`input-name-error-${(data.languageIso as string).toLowerCase()}`"
              >
                {{ (val.$errors[0].$message as string)[index].toString() }}
              </small>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>

    <template #footer>
      <div class="flex flex-row-reverse justify-between">
        <PrimeButton
          :label="t(`common.cancel`)"
          data-testid="cancel-dialog-btn"
          @click="closeDialog"
          class="c-dialog-default-button"
          severity="cancel"
          text
          :pt="{
            label: 'font-bold -mx-6',
          }"
          icon="pi pi-times"
        />
      </div>
      <div class="flex items-center justify-center">
        <PrimeButton
          :label="t('common.save')"
          data-testid="btn-save-product-group-name"
          @click="onSave"
          class="c-dialog-default-button c-dialog-success-button"
          icon="pi pi-check"
          :pt="{
            label: '-mx-4',
          }"
        />
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { helpers, required } from "@vuelidate/validators";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { NewProductGroupName } from "../model/NewProductGroupName";

import useVuelidate from "@vuelidate/core";
import { LanguageText } from "../model/LanguageText";
import { LanguageIsoType } from "../model/LanguageIsoType";
import { useProductHierarchyStore } from "../api/ProductHierarchyStore";

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const productHierarchyStore = useProductHierarchyStore();

const newProductGroupName = ref<NewProductGroupName>(new NewProductGroupName());
newProductGroupName.value.id = uuidv4();

Object.keys(LanguageIsoType).forEach((key: string) => {
  const language = LanguageIsoType[key as keyof typeof LanguageIsoType];
  newProductGroupName.value.names.push(LanguageText.createLanguageText(language, ""));
});

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    return;
  }

  const success = await productHierarchyStore.addProductGroupName(newProductGroupName.value);
  if (success) {
    closeDialog();
  }
};

const rules = {
  names: {
    $each: helpers.forEach({
      text: {
        required: helpers.withMessage(t("validations.required"), required),
      },
    }),
  },
};

const val = useVuelidate(rules, newProductGroupName.value);
const closeDialog = async () => {
  visible.value = false;
};

const copyFirstGroupNameToAllLanguage = (data: string, index: number) => {
  if (index !== 0) return;

  if (data) {
    for (let i = 1; i < newProductGroupName.value.names.length; i++) {
      if (newProductGroupName.value.names[i].text.length < 1) {
        newProductGroupName.value.names[i].text = data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.c-add-product-group-name-dialog {
  width: 25vw;
}
:deep(.c-add-product-group-name-table.p-datatable) {
  .p-datatable-thead > tr > th {
    border: none;
    background: transparent;
  }

  .p-datatable-tbody > tr {
    background: transparent;

    &:focus {
      outline: none;
    }

    & > td {
      border: none;
    }
  }

  &.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: transparent;
  }
}
</style>
