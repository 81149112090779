import { httpClient, AuthHeaders } from "@cumulus/http";
import { NewProduct } from "./model/NewProduct";
import { Product } from "./model/Product";
import { UpdateProduct } from "./model/UpdateProduct";

class ProductApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/products"
        : `${import.meta.env.VITE_APP_API_URL as string}/products`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Product[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Product> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, product: NewProduct): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", product);
  }

  public async update(authHeaders: AuthHeaders, product: UpdateProduct): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", product);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}
const productApi = new ProductApi();
export { productApi };
