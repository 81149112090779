<template>
  <DataTable
    :value="warehousesWithCostPriceComputed"
    class="mr-6"
    dataKey="id"
    data-testid="product-warehouses"
    :autoLayout="true"
    responsiveLayout="stack"
    breakpoint="991px"
    :globalFilterFields="['name']"
    stripedRows
    :tabindex="-1"
    size="small"
  >
    <template #header>
      <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="font-bold">{{ t("product.warehouse.title") }}</span>

        <PrimeButton
          id="add-warehouse-button"
          type="button"
          class="p-button-text p-button-sm"
          data-testid="add-warehouse-btn"
          @click.stop="showDialogAdd = true"
        >
          <i class="c-plus"></i>
          <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
        </PrimeButton>
      </div>
    </template>

    <template #empty>
      <div class="c-list-not-found" :class="{ 'p-invalid': val.warehouseIds.$error }">
        {{ t(`product.warehouse.no-warehouse-found`) }}
      </div></template
    >

    <Column field="name" :header="t(`product.warehouse.name`)" :sortable="true" :tabindex="-1">
      <template #body="{ data }">
        {{ data.name }}
      </template>
    </Column>

    <Column field="name" :header="t(`product.warehouse.cost-price`)" :sortable="true" :tabindex="-1">
      <template #body="{ data, index }">
        <span :data-testid="`warehouse-cost-price-value-row-${index}`">{{ n(data.costPrice ?? 0, "decimal") }} </span>
      </template>
    </Column>

    <Column class="c-product-edit-warehouse-column-remove">
      <template #body="slotProps">
        <PrimeButton
          icon="pi pi-trash"
          data-testid="delete-warehouse-btn"
          class="p-button-text p-button-rounded p-button-secondary"
          @click.stop="onConfirmDelete($event, slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>

  <small id="warehouse-help" class="p-error" data-testid="warehouse-error" v-if="val.warehouseIds.$error">
    {{ val.warehouseIds.$errors[0].$message }}
  </small>

  <WarehouseDialogAdd
    :selectedWarehouseIds="warehouseIds"
    :warehouses="warehouses"
    v-model:showDialogAdd="showDialogAdd"
    @addWarehouse="$emit('addWarehouse', $event)"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, watch, ref, nextTick } from "vue";
import { NIL as emptyUuid } from "uuid";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";

import WarehouseDialogAdd from "./WarehouseDialogAdd.vue";
import { WarehouseProduct } from "@/repositories/warehouse/model/WarehouseProduct";
import { WarehouseExtended } from "@/repositories/warehouse/model/WarehouseExtended";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const { getAllWarehouses, getWarehouseProducts } = useWarehouse();
const { t, n } = useI18n();

const props = defineProps<{
  warehouseIds: string[];
  productId?: string;
}>();

const emit = defineEmits<{
  (e: "addWarehouse", value: string[]): void;
  (e: "deleteWarehouse", value: string): void;
}>();

const showDialogAdd = ref(false);
const loading = ref(true);
const warehouses = ref<Warehouse[]>([]);
const warehouseProducts = ref<WarehouseProduct[]>([]);

const loadWarehouses = async () => {
  loading.value = true;
  warehouses.value = await getAllWarehouses();

  if (props.productId !== emptyUuid && props.productId !== undefined) {
    warehouseProducts.value = await getWarehouseProducts(props.productId);
  }

  loading.value = false;
};

watch(props, async () => {
  if (props.productId !== emptyUuid) {
    loadWarehouses();
  }
});

const warehousesWithCostPriceComputed = computed<WarehouseExtended[]>(() => {
  return props.warehouseIds.map((warehouseId: string) => {
    const warehouse = warehouses.value.find((w: Warehouse) => w.id === warehouseId);
    const warehouseProduct = warehouseProducts.value.find((wp: WarehouseProduct) => wp.warehouseId === warehouseId);
    return {
      ...warehouse,
      costPrice: warehouseProduct?.costPrice,
    };
  }) as WarehouseExtended[];
});

onMounted(loadWarehouses);

const deleteWarehouse = async (warehouse: WarehouseExtended) => {
  emit("deleteWarehouse", warehouse.id);
  await nextTick();
  document.getElementById("add-warehouse-button")?.focus();
};

const rules = {
  warehouseIds: {
    required: required,
  },
};
const val = useVuelidate(rules, props);

const onConfirmDelete = (event: Event, warehouse: WarehouseExtended) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle !text-2xl",
    acceptClass: "ml-4 p-button-danger",
    rejectClass: "p-button-text",
    acceptLabel: t("common.yes"),
    rejectLabel: t("common.no"),
    defaultFocus: "accept",
    accept: async () => {
      deleteWarehouse(warehouse);
    },
  });
};
</script>
