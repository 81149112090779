<template>
  <Popover
    ref="attributePanelRef"
    appendTo="body"
    :dismissable="!isDialogActive"
    id="attribute_overlay_panel"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '400px' }"
    @hide="hideOverlayPanel"
    @keydown.esc.stop="hideOverlayPanel"
    class="c-minimal-inputtext c-minimal-inputgroup"
  >
    <AttributeName
      v-if="attributeType === AttributeType.Name"
      :productAttributes="productAttributes"
      @onAttributeSelect="onAttributeSelect"
      @isDialogOpen="isDialogActive = $event"
    />
    <AttributeValues
      v-if="attributeType === AttributeType.Values"
      :selectedAttributeId="selectedAttributeComputed.id"
      v-model:selectedAttributeValues="selectedAttributeValuesComputed"
    />
  </Popover>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { AttributeType } from "@/repositories/attribute/model/AttributeType";
import { Attribute } from "@/repositories/attribute/model/Attribute";
import AttributeName from "./AttributeName.vue";
import AttributeValues from "./AttributeValues.vue";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";

const props = defineProps<{
  selectedAttributeValues?: string[];
  attributeType: AttributeType;
  productAttributes: ProductAttribute[];
  selectedAttribute?: ProductAttribute;
}>();

const emit = defineEmits<{
  (e: "update:selectedAttributeValues", value: string[]): void;
  (e: "onAttributeSelect", value: Attribute): void;
}>();

const isDialogActive = ref(false);

const attributePanelRef = ref();

const onAttributeSelect = async (attribute: Attribute) => {
  emit("onAttributeSelect", attribute);
  hideOverlayPanel();
};

const selectedAttributeComputed = computed(() => {
  return props.selectedAttribute ? props.selectedAttribute : new ProductAttribute();
});

const selectedAttributeValuesComputed = computed<string[]>({
  get: () => (props.selectedAttributeValues ? props.selectedAttributeValues : []),
  set: (value) => {
    emit("update:selectedAttributeValues", value);
  },
});

const hideOverlayPanel = () => {
  attributePanelRef.value.hide();
};

const toggle = (event: Event) => {
  attributePanelRef.value.toggle(event);
};
defineExpose({ toggle: toggle });
</script>
