<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('product-group-name-dialog.edit-dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @keydown.esc="closeDialog"
    class="c-edit-product-group-name-dialog"
    data-testid="edit-product-group-name-dialog"
  >
    <div>
      <DataTable
        :value="state.names"
        class="c-edit-product-group-name-table"
        dataKey="iso"
        :autoLayout="true"
        responsiveLayout="scroll"
        selectionMode="single"
        editMode="row"
        size="small"
        data-testid="edit-product-group-name-list"
      >
        <Column field="iso" :header="t(`product-group-name-dialog.header-language`)">
          <template #body="{ data }">
            {{ t("product-group-name-dialog.language." + (data.languageIso as string).toLowerCase()) }}
          </template>
        </Column>

        <Column field="text" :header="t(`product-group-name-dialog.header-name`)" class="c-td-name">
          <template #body="{ data, field, index }">
            <InputText
              v-model="data[field]"
              :class="{ 'p-invalid': val.$error && (val.$errors[0].$message as string)[index].toString() }"
              autofocus="true"
              :data-testid="`input-name-${(data.languageIso as string).toLowerCase()}`"
              @blur="copyFirstGroupNameToAllLanguage(data[field], index)"
            />

            <div>
              <small
                class="p-error"
                v-if="val.$error"
                :data-testid="`input-name-error-${(data.languageIso as string).toLowerCase()}`"
              >
                {{ (val.$errors[0].$message as string)[index].toString() }}
              </small>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>

    <template #footer>
      <div class="flex flex-row-reverse justify-between">
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-dialog-btn"
            @click="closeDialog"
            class="c-dialog-default-button mr-4"
            severity="cancel"
            text
            :pt="{
              label: 'font-bold -mx-6',
            }"
            icon="pi pi-times"
          />
          <PrimeButton
            :label="t('common.save')"
            data-testid="btn-save-product-group-name"
            @click="onSave"
            class="c-dialog-default-button c-dialog-success-button"
            icon="pi pi-check"
            :pt="{
              label: '-mx-4',
            }"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { helpers, required } from "@vuelidate/validators";
import { computed, watch, reactive, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { ProductGroupName } from "../model/ProductGroupName";
import { LanguageText } from "../../models/language/LanguageText";
import { LanguageIsoType } from "../../models/language/LanguageIsoType";
import useVuelidate from "@vuelidate/core";
import { useProductHierarchyStore } from "../api/ProductHierarchyStore";

const productHierarchyStore = useProductHierarchyStore();

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
  productGroupName: ProductGroupName;
}>();

const emit = defineEmits<{
  (e: "editProductGroupName", value: ProductGroupName): void;
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const state = reactive({
  names: [] as LanguageText[],
});

Object.keys(LanguageIsoType).forEach((key: string) => {
  const language = LanguageIsoType[key as keyof typeof LanguageIsoType];
  state.names.push(LanguageText.createLanguageText(language, ""));
});

const featchGroupNames = (productGroupName: ProductGroupName) => {
  productGroupName.names.forEach((name) => {
    const index = state.names.findIndex((n) => n.languageIso === name.languageIso);
    if (index > -1) {
      state.names[index].text = name.text;
    }
  });
};

watch(
  () => props.productGroupName,
  (productGroupName) => {
    featchGroupNames(productGroupName);
  },
);

onMounted(() => {
  featchGroupNames(props.productGroupName);
});

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    return;
  }

  const isUnchanged = props.productGroupName.names.every(function (name) {
    const value = state.names.find((n) => n.languageIso === name.languageIso);
    return value?.text === name.text;
  });

  if (!isUnchanged) {
    const productGroupName = new ProductGroupName();
    productGroupName.id = props.productGroupName.id;
    productGroupName.companyId = props.productGroupName.companyId;
    productGroupName.names = state.names;
    const success = await productHierarchyStore.updateProductGroupName(productGroupName);
    if (success) {
      closeDialog();
    }
  }
};

const rules = {
  names: {
    $each: helpers.forEach({
      text: {
        required: helpers.withMessage(t("validations.required"), required),
      },
    }),
  },
};

const val = useVuelidate(rules, state);
const closeDialog = async () => {
  visible.value = false;
};

const copyFirstGroupNameToAllLanguage = (data: string, index: number) => {
  if (index !== 0) return;

  if (data) {
    for (let i = 1; i < state.names.length; i++) {
      if (state.names[i].text.length < 1) {
        state.names[i].text = data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.c-edit-product-group-name-dialog {
  width: 25vw;
}
:deep(.c-edit-product-group-name-table.p-datatable) {
  .p-datatable-thead > tr > th {
    border: none;
    background: transparent;
  }

  .p-datatable-tbody > tr {
    background: transparent;

    &:focus {
      outline: none;
    }

    & > td {
      border: none;
    }
  }

  &.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: transparent;
  }
}
</style>
